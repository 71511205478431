import React, { Dispatch, SetStateAction } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { DownloaderContent } from "./DownloaderContent";

const queryClient = new QueryClient();

interface Props {
  state: regionState;
}

interface regionState {
  region: string;
  setRegion: Dispatch<SetStateAction<string>>;
}

export const Downloader = ({ state }: Props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <DownloaderContent state={{ ...state }} />
    </QueryClientProvider>
  );
};
