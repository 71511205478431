import {
  Typography,
  Grid,
  Box,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import { Downloader } from "../components/Downloader";
import { Header } from "../components/Header";
import { Map } from "../components/Map";
import { Footer } from "../components/Footer";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    Grid: {
      width: "90%",
      paddingTop: "1%",
      paddingLeft: "10%",
    },
    a: {
      color: "white",
    },
    Grid2: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 0,
    },

    bodyStyling: {
      height: "100vh",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    },
  })
);

const theme = createTheme();

export const Downloads = () => {
  const [region, setRegion] = React.useState<string>("africa");

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <CssBaseline />
        <div className={classes.bodyStyling}>
          <Header />
          <Grid className={classes.Grid2}    >
            <Grid container className={classes.Grid}>
              <Grid
                item
                xs={12} md={6}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Typography
                  variant="h2"
                  display="block"
                  style={{ fontWeight: 600, color: "white" }}
                >
                  Download your Map
                </Typography>
                <Downloader state={{ region, setRegion }} />
              </Grid>

              <Grid item  xs={12} md={6} style={{ paddingLeft: "5%" }}>
                <Map region={region} />
                <Box style={{ marginTop: "25px" }}>
                  <Typography
                    variant="h4"
                    display="block"
                    style={{ fontWeight: 600, color: "white" }}
                  >
                    PostgreSQL and OpenStreetMap
                  </Typography>
                  <Typography
                    variant="h6"
                    display="block"
                    style={{
                      fontWeight: 500,
                      color: "white",
                      width: "75%",
                      paddingTop: "20px",
                    }}
                  >
                    CYBERTEC implemented a “download OpenStreetMap” service
                    which periodically generates extracts of OpenStreetMap data
                    in various forms, and outputs the data as an sql dump to
                    streamline and simplify its usage. Extracts cover a
                    particular region of interest– typically, aligned with the
                    boundaries of countries or continents. Extract imports’
                    parameters have already been set for your convenience.
                    Import variants implicitly state how your dataset was
                    imported into our database. New variants can be created
                    according to your wishes,{" "}
                    <a
                      className={classes.a}
                      href="https://www.cybertec-postgresql.com/en/contact/"
                    >
                      contact us{" "}
                    </a>{" "}
                    for more details.
                  </Typography>
                  <Box
                    style={{
                      fontWeight: 500,
                      width: "65%",
                      backgroundColor: "#444444",
                      marginTop: "30px",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: 500,
                        color: "white",
                        paddingTop: "20px",
                        paddingLeft: "20px",
                        paddingBottom: "10px",
                      }}
                    >
                      OpenStreetMap® is open data, licensed under the Open Data
                      Commons Open Database License (ODbL) by the OpenStreetMap
                      Foundation (OSMF).
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Footer />
        </div>
      </React.Fragment>
    </ThemeProvider>
  );
};

export default Downloads;
