import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    Footer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#EFEEEE",
      backgroundColor: "#111111",
      width: "100%",
      height: "50px",
      minHeight: "50px",
    },
    FooterBox: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: "30px",
      gap: "10px",
    },
  })
);
