import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "../assets/cybertec_logo.png";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    AppBar: {
      backgroundColor: "#171717",
      height: 110,
      justifyContent: "center",
    },
    ToolBar: {
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
    },
    logo: {
      maxWidth: 85,
      marginRight: "20px",
    },
    Button: {
      padding: "12px",
      paddingLeft: "23px",
      paddingRight: "23px",
      marginRight: "20px",
      borderRadius: 100,
      backgroundColor: "#244588",
      color: "#EFEEEE",
    },
  })
);

export const Header = () => {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.AppBar}>
      <Toolbar className={classes.ToolBar}>
        <a href="https://www.cybertec-postgresql.com/en">
          <img src={logo} alt="logo" className={classes.logo} />
        </a>
        <Button
          variant="contained"
          href="https://www.cybertec-postgresql.com/en/contact/"
          className={classes.Button}
        >
          CONTACT US
        </Button>
      </Toolbar>
    </AppBar>
  );
};
