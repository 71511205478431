import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import ListItemText from "@material-ui/core/ListItemText";
import { IconButton } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import LibraryBooksOutlinedIcon from "@material-ui/icons/LibraryBooksOutlined";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import type { Dataset, DatasetItem, EntitiesItem } from "./DownloaderContent";
import React, { Dispatch, SetStateAction } from "react";

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const useStyles = makeStyles(() =>
  createStyles({
    List: {
      flex: 1,
      backgroundColor: "#EFEEEE",
      borderRadius: "5px",
    },
    Text: {
      marginTop: "8px",
      color: "#7A7A7A",
      paddingRight: "12px",
    },
    Details: {
      flexDirection: "column",
    },
    Accordion: {
      borderRadius: "4px",
      boxShadow: "none",
      marginTop: "10px",
      border: "1px solid",
      "&::before": {
        content: "unset",
      },
      "&.Mui-expanded": {
        margin: "0px",
        marginTop: "7px",
      },
      "&.MuiAccordion-rounded": {
        borderRadius: "4px",
      },
    },

    Tooltip: {
      visibility: "hidden",
    },

    Paper3: {
      maxWidth: "70%",
      padding: "18px",
      border: "1 , 5 px solid",
      borderRadius: "4px",
      height: "auto",
      marginTop: "20px",
    },
  })
);

interface Props {
  data: Dataset | undefined;
  dataItem: DatasetItem;
  name: string;
  state: regionState;
}

interface regionState {
  region: string;
  setRegion: Dispatch<SetStateAction<string>>;
}

export const DownloaderRows = ({ data, dataItem, name, state }: Props) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = () => {
    setExpanded(expanded ? false : true);
  };

  const handleClick = () => {
    state.setRegion(dataItem.name);
    handleChange();
  };

  if (state.region === dataItem.name) {
    return (
      <Accordion
        variant="outlined"
        className={classes.Accordion}
        onChange={handleClick}
        expanded={state.region === dataItem.name}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{name}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.Details}>
          <List className={classes.List}>
            {dataItem.entities.map((entityItems: EntitiesItem) => {
              return (
                <ListItem>
                  <ListItemText
                    primary={
                      entityItems.usage +
                      " (" +
                      entityItems.import_type_name +
                      ")"
                    }
                  />
                  <Box display="flex" alignItems="center">
                    <ListItemText
                      primary={entityItems.export_time_stamp}
                      className={classes.Text}
                    />
                    <Tooltip title="Download">
                      <IconButton
                        aria-label="delete"
                        href={entityItems.url}
                        style={{ color: "#244588" }}
                      >
                        <SystemUpdateAltIcon fontSize="medium" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      title=".README"
                      className={
                        entityItems.import_type_name === "osmium"
                          ? classes.Tooltip
                          : ""
                      }
                    >
                      <IconButton
                        aria-label="delete"
                        href={entityItems.restoreInstructions}
                        target="_blank"
                      >
                        <LibraryBooksOutlinedIcon fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </ListItem>
              );
            })}
          </List>
          {name === "Antarctica" ? undefined : (
            <Accordion variant="outlined" className={classes.Accordion}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  {name === "Russia" ? "Regions" : " Countries"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.Details}>
                {data?.datasets?.map((dataCountry: DatasetItem) => {
                  if (dataCountry.parent === dataItem.name) {
                    return (
                      <Accordion
                        variant="outlined"
                        className={classes.Accordion}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>
                            {capitalizeFirstLetter(
                              dataCountry.name.replace(/-/g, " ")
                            )}{" "}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.Details}>
                          <List className={classes.List} key={"test"}>
                            {dataCountry.entities.map(
                              (entetieItems: EntitiesItem, index: number) => {
                                return (
                                  <ListItem>
                                    <ListItemText
                                      primary={
                                        entetieItems.usage +
                                        " (" +
                                        entetieItems.import_type_name +
                                        ")"
                                      }
                                    />
                                    <Box display="flex" alignItems="center">
                                      <ListItemText
                                        primary={entetieItems.export_time_stamp}
                                        className={classes.Text}
                                      />
                                      <Tooltip title="Download">
                                        <IconButton
                                          aria-label="delete"
                                          href={entetieItems.url}
                                          style={{ color: "#244588" }}
                                        >
                                          <SystemUpdateAltIcon fontSize="medium" />
                                        </IconButton>
                                      </Tooltip>

                                      <Tooltip
                                        title=".README"
                                        className={
                                          entetieItems.import_type_name ===
                                          "osmium"
                                            ? classes.Tooltip
                                            : ""
                                        }
                                      >
                                        <IconButton
                                          aria-label="delete"
                                          href={
                                            entetieItems.restoreInstructions
                                          }
                                          target="_blank"
                                        >
                                          <LibraryBooksOutlinedIcon fontSize="medium" />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </ListItem>
                                );
                              }
                            )}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    );
                  } else {
                    return null;
                  }
                })}
              </AccordionDetails>
            </Accordion>
          )}
        </AccordionDetails>
      </Accordion>
    );
  } else {
    return (
      <Accordion
        variant="outlined"
        className={classes.Accordion}
        onChange={handleClick}
        expanded={state.region === dataItem.name}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{name}</Typography>
        </AccordionSummary>
      </Accordion>
    );
  }
};
