import { Box, Link, Typography } from "@material-ui/core";
import { useStyles } from "./DownloaderStyles";

export const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.Footer}>
      <Typography style={{ paddingRight: "30px" }}>
        © 2000–2021 CYBERTEC PostgreSQL International GmbH
      </Typography>
      <Box className={classes.FooterBox}>
        <Link
          href={
            "https://www.cybertec-postgresql.com/en/data-protection-policy/"
          }
        >
          <Typography style={{ color: "#EFEEEE" }}>
            Data protection policy
          </Typography>
        </Link>
        <Link href={"https://www.cybertec-postgresql.com/en/legal-notice/"}>
          <Typography style={{ color: "#EFEEEE" }}>Imprint</Typography>
        </Link>
        <Link
          href={"https://www.cybertec-postgresql.com/en/terms-and-conditions/"}
        >
          <Typography style={{ color: "#EFEEEE" }}>
            Terms and Conditions
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};
