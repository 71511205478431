import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";

//import WorldMap from "../assets/WorldMap.svg";

interface Props {
  region?: string;
}

export const useStyles = makeStyles(() =>
  createStyles({
    Paper3: {
      maxWidth: "70%",
      padding: "18px",
      border: "1 , 5 px solid",
      borderRadius: "4px",
      height: "auto",
      marginTop: "20px",
    },
  })
);

function SvgComponent({ region }: Props) {
  return (
    <svg
      id="Layer_1"
      viewBox="0 0 1450 980"
      enableBackground="new 0 0 1400 980"
    >
      <g id="XMLID_143_">
        <g id="asia" fill={region === "asia" ? "#244588" : undefined}>
          <path
            id="XMLID_8039_"
            d="m 843.1,470.8 c 4,0 7.4,3.3 7.3,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.1,-4.1 3,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8040_"
            d="m 874.4,478.3 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.2 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.8 4,0 7.3,3.4 7.2,7.4 z"
          />
          <path
            id="XMLID_8265_"
            d="m 898.4,430.1 c 0,4 -3.3,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-7 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8393_"
            d="m 867.2,446.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.2 -6.8,-3.2 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8645_"
            d="m 855,482.9 c 4.1,-0.1 7.3,3 7.3,7.2 0,4 -2.9,7.1 -6.7,7.2 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8769_"
            d="m 862.3,442.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_8770_"
            d="m 862.3,466.3 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.2 -6.9,-3.3 -7,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 4.1,-0.1 7.2,3.1 7.1,7.3 z"
          />
          <path
            id="XMLID_8847_"
            d="m 1143.3,473.3 c -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.2,4.3 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8844_"
            d="m 1071.1,530.9 c 4.1,0 7.2,3.1 7.2,7.3 -0.1,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-3.9 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_288_"
            d="m 1095.1,530.9 c 4.1,0 7.2,3.1 7.2,7.3 -0.1,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-3.9 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_290_"
            d="m 1143.1,530.9 c 4.1,0 7.2,3.1 7.2,7.3 -0.1,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-3.9 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8843_"
            d="m 1047.2,545.3 c -4,0 -7.4,-3.3 -7.3,-7.3 0,-4 3.4,-7.2 7.4,-7.1 3.9,0.1 6.9,3.1 7,7 0.1,4.2 -3,7.4 -7.1,7.4 z"
          />
          <path
            id="XMLID_8842_"
            d="m 1023.2,545.3 c -4,0 -7.4,-3.3 -7.3,-7.3 0,-4 3.4,-7.2 7.4,-7.1 3.9,0.1 6.9,3.1 7,7 0.1,4.2 -3,7.4 -7.1,7.4 z"
          />
          <path
            id="XMLID_8841_"
            d="m 999,545.3 c -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.6,-7.1 7.6,-7 3.9,0.2 6.8,3.2 6.8,7.2 0.1,4.1 -3.1,7.3 -7.2,7.2 z"
          />
          <path
            id="XMLID_8840_"
            d="m 982.3,538 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.2,-7 7,-7.1 4.2,0 7.4,3.1 7.4,7.2 z"
          />
          <path
            id="XMLID_8834_"
            d="m 951.2,530.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -7,7 -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-3.9 3.4,-7.2 7.4,-7.2 z"
          />
          <path
            id="XMLID_8833_"
            d="m 934.3,538 c 0.1,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_8832_"
            d="m 903.1,530.9 c 4.1,0 7.2,3.1 7.2,7.3 0,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3 -7.6,-7 0,-4 3.3,-7.4 7.3,-7.4 z"
          />
          <path
            id="XMLID_8831_"
            d="m 879,545.3 c -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.6,-7.1 7.6,-7 3.9,0.2 6.8,3.2 6.8,7.2 0.1,4.1 -3.1,7.3 -7.2,7.2 z"
          />
          <path
            id="XMLID_303_"
            d="m 879,569.3 c -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.6,-7.1 7.6,-7 3.9,0.2 6.8,3.2 6.8,7.2 0.1,4.1 -3.1,7.3 -7.2,7.2 z"
          />
          <path
            id="XMLID_8830_"
            d="m 1119.4,473.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 3.9,0 7,2.9 7.2,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8827_"
            d="m 855.4,545.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 3.9,0 7,2.9 7.2,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8823_"
            d="m 831.4,545.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 3.9,0 7,2.9 7.2,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8822_"
            d="m 814.3,538.2 c 0,4.1 -3.3,7.3 -7.4,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 4.2,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_8798_"
            d="m 1102.3,466.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,-0.1 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8797_"
            d="m 1071.2,473.3 c -4,0 -7.4,-3.3 -7.4,-7.2 0,-4 3.4,-7.2 7.4,-7.2 3.9,0.1 6.9,3 7,7 0.2,4.1 -2.9,7.4 -7,7.4 z"
          />
          <path
            id="XMLID_8796_"
            d="m 1054.3,466.3 c -0.1,4.1 -3.4,7.2 -7.4,7 -3.8,-0.2 -6.9,-3.4 -7,-7.1 0,-4 3.3,-7.3 7.3,-7.3 4.1,0 7.2,3.2 7.1,7.4 z"
          />
          <path
            id="XMLID_8793_"
            d="m 1023.2,473.3 c -4,0 -7.4,-3.3 -7.4,-7.2 0,-4 3.4,-7.2 7.4,-7.2 3.9,0.1 6.9,3 7,7 0.2,4.2 -2.9,7.4 -7,7.4 z"
          />
          <path
            id="XMLID_8792_"
            d="m 1006.3,466.3 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.2 -6.9,-3.3 -7,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 4.1,-0.1 7.2,3.1 7.1,7.3 z"
          />
          <path
            id="XMLID_8781_"
            d="m 975.4,473.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -6.9,7.5 z"
          />
          <path
            id="XMLID_8780_"
            d="m 951.1,458.9 c 4.1,0 7.2,3.1 7.2,7.3 -0.1,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8779_"
            d="m 934.3,466.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_8776_"
            d="m 1102.3,442 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_8772_"
            d="m 902.9,458.9 c 4.1,-0.1 7.3,3 7.4,7.1 0,4 -2.8,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-3.9 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8771_"
            d="m 879.2,458.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -6.9,7 -4,0.1 -7.4,-3.2 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 z"
          />
          <path
            id="XMLID_8768_"
            d="m 886.3,441.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_8766_"
            d="m 903.4,434.9 c 4.1,0.1 7.1,3.3 6.9,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.3,-3.3 -7.3,-7.3 0,-4 3.4,-7.2 7.5,-7.1 z"
          />
          <path
            id="XMLID_8761_"
            d="m 1078.3,514 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,-0.1 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_291_"
            d="m 1174.3,514 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,-0.1 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_8760_"
            d="m 1046.9,506.9 c 4.1,-0.1 7.3,3 7.4,7.1 0,4 -2.8,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-3.9 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8759_"
            d="m 1023,521.3 c -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-4 3.6,-7.1 7.7,-6.9 3.9,0.2 6.8,3.3 6.7,7.2 0,4.2 -3.3,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_8751_"
            d="m 1006.3,514.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8748_"
            d="m 974.9,506.9 c 4.1,-0.1 7.3,3 7.4,7.1 0,4 -2.8,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-3.9 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8746_"
            d="m 958.3,514.3 c -0.1,4.1 -3.4,7.2 -7.5,7 -3.8,-0.2 -6.9,-3.4 -6.9,-7.2 0,-4 3.3,-7.3 7.4,-7.2 4.1,0.1 7.1,3.2 7,7.4 z"
          />
          <path
            id="XMLID_8745_"
            d="m 934.3,514.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8744_"
            d="m 910.3,513.9 c 0.1,4.2 -3,7.4 -7,7.4 -4,0 -7.4,-3.2 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.1,-0.2 7.4,2.8 7.5,7 z"
          />
          <path
            id="XMLID_8743_"
            d="m 886.3,513.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_8742_"
            d="m 855.4,521.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -6.9,7.5 z"
          />
          <path
            id="XMLID_8736_"
            d="m 1006.3,442 c 0,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_8734_"
            d="m 1030.3,441.9 c 0.1,4.2 -3,7.4 -7,7.4 -4,0 -7.4,-3.3 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.2,-0.2 7.4,2.9 7.5,7 z"
          />
          <path
            id="XMLID_8666_"
            d="m 1078.3,442.2 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.1 -6.9,-3.3 -7,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 4.1,0 7.2,3.2 7.1,7.3 z"
          />
          <path
            id="XMLID_8659_"
            d="m 1119,482.9 c 4.1,0 7.3,3.1 7.2,7.2 0,3.9 -2.9,7 -6.8,7.2 -4,0.2 -7.5,-3 -7.6,-7 -0.1,-3.9 3.2,-7.4 7.2,-7.4 z"
          />
          <path
            id="XMLID_285_"
            d="m 1191,482.9 c 4.1,0 7.3,3.1 7.2,7.2 0,3.9 -2.9,7 -6.8,7.2 -4,0.2 -7.5,-3 -7.6,-7 -0.1,-3.9 3.2,-7.4 7.2,-7.4 z"
          />
          <path
            id="XMLID_8656_"
            d="m 1102.3,490 c 0.1,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_8655_"
            d="m 1078.3,490.2 c 0,4.1 -3.3,7.3 -7.4,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 4.2,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_8654_"
            d="m 1039.9,489.4 c 0.5,-4 4,-6.9 8,-6.5 4,0.4 6.7,3.9 6.3,8.1 -0.4,3.8 -3.8,6.6 -7.7,6.3 -3.9,-0.3 -7,-4 -6.6,-7.9 z"
          />
          <path
            id="XMLID_8652_"
            d="m 1030.3,489.8 c 0.1,4.2 -2.9,7.4 -7,7.5 -4,0 -7.4,-3.2 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.1,-0.2 7.4,2.8 7.5,6.9 z"
          />
          <path
            id="XMLID_8651_"
            d="m 999,482.9 c 4.1,-0.1 7.3,3 7.3,7.2 0,4 -2.9,7.1 -6.7,7.2 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8650_"
            d="m 975.4,497.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 3.9,0 7,2.9 7.2,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8649_"
            d="m 950.9,482.9 c 4.1,-0.1 7.3,3 7.4,7.1 0,4 -2.8,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-3.9 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8648_"
            d="m 927.8,482.9 c 4,0.3 6.8,3.7 6.5,7.9 -0.3,3.8 -3.6,6.7 -7.5,6.5 -4,-0.2 -7.2,-3.8 -6.9,-7.7 0.3,-4 3.8,-7 7.9,-6.7 z"
          />
          <path
            id="XMLID_8647_"
            d="m 903.8,482.9 c 4,0.3 6.8,3.7 6.5,7.9 -0.3,3.8 -3.6,6.7 -7.5,6.5 -4,-0.2 -7.2,-3.8 -6.9,-7.7 0.3,-4 3.8,-7 7.9,-6.7 z"
          />
          <path
            id="XMLID_8646_"
            d="m 886.3,490.2 c 0,4.1 -3.3,7.3 -7.4,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 4.2,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_8624_"
            d="m 934.3,442.3 c -0.1,4.1 -3.4,7.2 -7.5,7 -3.8,-0.2 -6.9,-3.4 -6.9,-7.2 0,-4 3.3,-7.3 7.4,-7.2 4.1,0.1 7.1,3.2 7,7.4 z"
          />
          <path
            id="XMLID_8617_"
            d="m 951.4,449.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 3.9,0 7,2.9 7.2,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8580_"
            d="m 982.3,442.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_8575_"
            d="m 1047.2,449.3 c -4,0 -7.3,-3.3 -7.3,-7.3 0.1,-4 3.4,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7 0,4.2 -3.1,7.4 -7.1,7.4 z"
          />
          <path
            id="XMLID_8555_"
            d="m 1126.3,442.2 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-3.8 3.4,-6.9 7.2,-6.9 4.1,-0.1 7.2,3 7.2,7.2 z"
          />
          <path
            id="XMLID_8536_"
            d="m 903.3,425.3 c -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.2,4.3 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8535_"
            d="m 934.3,418.2 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.1 -7,-3.3 -7,-7.1 -0.1,-4 3.2,-7.3 7.3,-7.3 4.1,0 7.1,3.2 7.1,7.3 z"
          />
          <path
            id="XMLID_8531_"
            d="m 1023.2,410.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -7,7 -4,0.1 -7.4,-3.2 -7.4,-7.2 -0.1,-3.9 3.3,-7.2 7.3,-7.2 z"
          />
          <path
            id="XMLID_8530_"
            d="m 1054.3,418.3 c -0.1,4.1 -3.4,7.2 -7.4,7 -3.8,-0.2 -6.9,-3.4 -7,-7.1 0,-4 3.3,-7.3 7.3,-7.3 4.1,0 7.2,3.2 7.1,7.4 z"
          />
          <path
            id="XMLID_8528_"
            d="m 1102.3,418.2 c 0,4.1 -3.3,7.3 -7.4,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 4.2,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_8517_"
            d="m 807.4,554.9 c 4.1,0.1 7.1,3.3 6.9,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.3,-3.3 -7.3,-7.3 0,-4 3.4,-7.2 7.5,-7.1 z"
          />
          <path
            id="XMLID_205_"
            d="m 831.4,554.9 c 4.1,0.1 7.1,3.3 6.9,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.3,-3.3 -7.3,-7.3 0,-4 3.4,-7.2 7.5,-7.1 z"
          />
          <path
            id="XMLID_8516_"
            d="m 903,554.9 c 4.1,0 7.3,3.1 7.2,7.2 0,3.9 -2.9,7 -6.8,7.2 -4,0.2 -7.5,-3 -7.6,-7 -0.1,-3.9 3.2,-7.4 7.2,-7.4 z"
          />
          <path
            id="XMLID_8515_"
            d="m 934.3,561.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_8514_"
            d="m 958.3,562.2 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.1 -7,-3.3 -7,-7.1 -0.1,-4 3.2,-7.3 7.3,-7.3 4.1,0 7.1,3.2 7.1,7.3 z"
          />
          <path
            id="XMLID_8513_"
            d="m 975.1,569.3 c -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-4 3.5,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7.1 0,4.2 -3.1,7.4 -7.2,7.4 z"
          />
          <path
            id="XMLID_8512_"
            d="m 1006.3,562.3 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.2 -6.9,-3.3 -7,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 4.1,-0.1 7.2,3.1 7.1,7.3 z"
          />
          <path
            id="XMLID_8511_"
            d="m 1030.3,562.2 c 0,4.1 -3.3,7.3 -7.4,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 4.2,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_8510_"
            d="m 1054.3,562 c 0.1,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_293_"
            d="m 1054.3,610 c 0.1,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_294_"
            d="m 1054.3,633.9 c 0.1,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,3 7.4,7.1 z"
          />
          <path
            id="XMLID_306_"
            d="m 1054.3,682.7 c 0.1,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,0 7.3,3 7.4,7.1 z"
          />
          <path
            id="XMLID_292_"
            d="m 1054.3,585.9 c 0.1,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,0 7.3,3 7.4,7.1 z"
          />
          <path
            id="XMLID_8509_"
            d="m 1071.4,569.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -6.9,7.5 z"
          />
          <path
            id="XMLID_8508_"
            d="m 1102.3,562 c 0,4.1 -3.1,7.3 -7.2,7.3 -4,-0.1 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_8499_"
            d="m 831.2,593.3 c -4,0 -7.3,-3.3 -7.3,-7.3 0.1,-4 3.4,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7 0,4.2 -3.1,7.4 -7.1,7.4 z"
          />
          <path
            id="XMLID_8498_"
            d="m 862.3,585.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_8497_"
            d="m 958.3,585.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_302_"
            d="m 958.3,609.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.2 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_8496_"
            d="m 1030.3,586.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_296_"
            d="m 1030.3,610.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_298_"
            d="m 1030.3,634.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,-0.1 7.2,3 7.2,7.2 z"
          />
          <path
            id="XMLID_304_"
            d="m 1030.3,658.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_299_"
            d="m 1078.3,658.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.3,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_308_"
            d="m 1102.3,658.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8404_"
            d="m 1131.3,461.3 c -4.1,0.1 -7.3,-3.1 -7.3,-7.2 0,-3.9 3,-7 6.8,-7.2 3.9,-0.2 7.4,3.1 7.6,7 0.1,4 -3.1,7.3 -7.1,7.4 z"
          />
          <path
            id="XMLID_8402_"
            d="m 1114.4,453.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8401_"
            d="m 1059.2,461.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.4 -7.2,7.4 z"
          />
          <path
            id="XMLID_8400_"
            d="m 1035.4,446.8 c 4,0.2 7.2,3.7 7,7.6 -0.2,4 -3.6,7.1 -7.7,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.3,-3.9 3.6,-6.9 7.4,-6.8 z"
          />
          <path
            id="XMLID_8399_"
            d="m 1018.4,454 c 0.1,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4.1,-0.2 7.5,3.1 7.6,7.1 z"
          />
          <path
            id="XMLID_8398_"
            d="m 987.2,446.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.2 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8397_"
            d="m 963,461.3 c -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.3,3.4 7.3,7.3 0,4 -3.4,7.2 -7.4,7.2 z"
          />
          <path
            id="XMLID_8396_"
            d="m 939.2,446.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.1 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8395_"
            d="m 915,461.3 c -4.1,-0.1 -7.2,-3.3 -7,-7.4 0.1,-3.9 3.2,-7 7.1,-7 4,0 7.4,3.3 7.3,7.3 0,3.9 -3.3,7.1 -7.4,7.1 z"
          />
          <path
            id="XMLID_8394_"
            d="m 891.3,461.3 c -4.1,0.1 -7.3,-3 -7.3,-7.2 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,4 -3.1,7.4 -7.1,7.5 z"
          />
          <path
            id="XMLID_8346_"
            d="m 1083.2,446.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.1 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8276_"
            d="m 1114.4,429.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8275_"
            d="m 1090.4,430.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8274_"
            d="m 1059.3,437.3 c -4.1,0.1 -7.3,-3 -7.3,-7.1 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,3.9 -3,7.3 -7.1,7.4 z"
          />
          <path
            id="XMLID_8273_"
            d="m 1042.4,429.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8272_"
            d="m 1010.9,422.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8270_"
            d="m 963.4,437.3 c -4.1,0.1 -7.3,-3 -7.4,-7.1 0,-4.1 3.2,-7.4 7.2,-7.4 3.8,0 7,3.1 7.2,6.9 0.2,4 -3,7.4 -7,7.6 z"
          />
          <path
            id="XMLID_8269_"
            d="m 946.4,430 c 0,4 -3.3,7.3 -7.3,7.2 -3.9,0 -7,-3 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,0 7.4,3.4 7.4,7.3 z"
          />
          <path
            id="XMLID_8268_"
            d="m 874.4,550 c 0,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,-0.1 7.4,3.2 7.4,7.2 z"
          />
          <path
            id="XMLID_8267_"
            d="m 915.2,422.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.1 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8225_"
            d="m 819.4,542.8 c 4,0.2 7.2,3.7 7,7.6 -0.2,4 -3.6,7.1 -7.7,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.3,-3.9 3.6,-6.9 7.4,-6.8 z"
          />
          <path
            id="XMLID_8212_"
            d="m 1083.2,533.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.4 -7.2,7.4 z"
          />
          <path
            id="XMLID_287_"
            d="m 1155.2,533.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.4 -7.2,7.4 z"
          />
          <path
            id="XMLID_8211_"
            d="m 1059,518.8 c 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 -3.9,0 -6.9,-3 -7.1,-6.9 -0.1,-4.1 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8210_"
            d="m 1042.4,526.5 c -0.3,4 -3.8,7 -7.8,6.7 -4.1,-0.3 -6.9,-3.6 -6.6,-7.8 0.3,-3.8 3.7,-6.8 7.4,-6.6 4.1,0.3 7.3,3.8 7,7.7 z"
          />
          <path
            id="XMLID_8204_"
            d="m 1018.4,525.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8203_"
            d="m 994.4,526.1 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8202_"
            d="m 970.4,525.9 c 0.1,4 -3.2,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8201_"
            d="m 939,533.3 c -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.3,3.4 7.3,7.3 0,4 -3.4,7.2 -7.4,7.2 z"
          />
          <path
            id="XMLID_8200_"
            d="m 915.2,533.3 c -4.1,0 -7.2,-3.2 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.1 3.9,-0.1 7.4,3.2 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_8199_"
            d="m 898.4,526.3 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.2 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.8 4,0 7.3,3.4 7.2,7.4 z"
          />
          <path
            id="XMLID_8198_"
            d="m 890.9,542.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8197_"
            d="m 922.4,550.5 c -0.3,4 -3.8,7 -7.8,6.7 -4.1,-0.3 -6.9,-3.6 -6.6,-7.8 0.3,-3.8 3.7,-6.8 7.4,-6.6 4.1,0.3 7.3,3.8 7,7.7 z"
          />
          <path
            id="XMLID_8196_"
            d="m 938.9,542.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8195_"
            d="m 963.1,542.8 c 4,0 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.2,-4.1 2.9,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8194_"
            d="m 987.1,542.8 c 4,-0.1 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-6.9 -0.2,-4.2 2.9,-7.5 7,-7.6 z"
          />
          <path
            id="XMLID_8193_"
            d="m 1010.9,542.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8192_"
            d="m 1035.3,557.3 c -4.1,0.1 -7.3,-3 -7.3,-7.2 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,4 -3.1,7.4 -7.1,7.5 z"
          />
          <path
            id="XMLID_8191_"
            d="m 1066.4,550.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8190_"
            d="m 1090.4,550.3 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.2 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.8 4,0 7.3,3.4 7.2,7.4 z"
          />
          <path
            id="XMLID_289_"
            d="m 1114,550.3 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.2 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.8 4,0 7.3,3.4 7.2,7.4 z"
          />
          <path
            id="XMLID_8189_"
            d="m 874.4,526.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8188_"
            d="m 850.4,526.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.4 -6.9,-7.5 0.2,-3.9 3.4,-6.9 7.2,-6.9 3.9,0 7.3,3.4 7.2,7.3 z"
          />
          <path
            id="XMLID_8163_"
            d="m 819.2,581.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0,-3.9 3,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.4 z"
          />
          <path
            id="XMLID_8162_"
            d="m 946.4,574 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4,-0.2 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8161_"
            d="m 970.4,573.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_300_"
            d="m 970.4,597.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8160_"
            d="m 994.4,574.3 c -0.1,4 -3.5,7.1 -7.6,7 -4.1,-0.2 -7,-3.5 -6.8,-7.6 0.2,-3.8 3.5,-6.9 7.3,-6.8 3.9,0 7.2,3.5 7.1,7.4 z"
          />
          <path
            id="XMLID_8159_"
            d="m 1018.4,574.5 c -0.3,4 -3.8,7 -7.8,6.7 -4.1,-0.3 -6.9,-3.6 -6.6,-7.8 0.3,-3.8 3.7,-6.8 7.4,-6.6 4.1,0.3 7.3,3.8 7,7.7 z"
          />
          <path
            id="XMLID_8158_"
            d="m 1042.4,574.1 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8157_"
            d="m 1059,581.3 c -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.3,3.4 7.3,7.3 0,4 -3.4,7.2 -7.4,7.2 z"
          />
          <path
            id="XMLID_8156_"
            d="m 1090.4,573.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8150_"
            d="m 1083,509.3 c -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.3,3.4 7.3,7.3 0,4 -3.4,7.2 -7.4,7.2 z"
          />
          <path
            id="XMLID_8149_"
            d="m 1058.9,494.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8135_"
            d="m 843.3,590.8 c 4,0.1 7.3,3.6 7.1,7.5 -0.2,4 -3.6,7.1 -7.6,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.2,-3.8 3.5,-6.8 7.2,-6.7 z"
          />
          <path
            id="XMLID_8134_"
            d="m 939.2,605.3 c -4.1,0 -7.2,-3.2 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.1 3.9,-0.1 7.4,3.2 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_8133_"
            d="m 1028,597.7 c 0.2,-3.9 3.3,-6.9 7.2,-6.9 4,0 7.3,3.4 7.3,7.4 -0.1,4 -3.4,7.2 -7.5,7.1 -4.2,-0.2 -7.2,-3.4 -7,-7.6 z"
          />
          <path
            id="XMLID_301_"
            d="m 1003.9,597.7 c 0.2,-3.9 3.3,-6.9 7.2,-6.9 4,0 7.3,3.4 7.3,7.4 -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.2 -7.1,-3.4 -7,-7.6 z"
          />
          <path
            id="XMLID_295_"
            d="m 1028,621.6 c 0.2,-3.9 3.3,-6.9 7.2,-6.9 4,0 7.3,3.4 7.3,7.4 -0.1,4 -3.4,7.2 -7.5,7.1 -4.2,-0.2 -7.2,-3.5 -7,-7.6 z"
          />
          <path
            id="XMLID_305_"
            d="m 1028,670.4 c 0.2,-3.9 3.3,-6.9 7.2,-6.9 4,0 7.3,3.4 7.3,7.4 -0.1,4 -3.4,7.2 -7.5,7.1 -4.2,-0.2 -7.2,-3.4 -7,-7.6 z"
          />
          <path
            id="XMLID_297_"
            d="m 1052,621.6 c 0.2,-3.9 3.3,-6.9 7.2,-6.9 4,0 7.3,3.4 7.3,7.4 -0.1,4 -3.4,7.2 -7.5,7.1 -4.2,-0.2 -7.2,-3.5 -7,-7.6 z"
          />
          <path
            id="XMLID_8131_"
            d="m 1042.4,502.1 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8130_"
            d="m 1018.4,502.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8129_"
            d="m 994.4,501.9 c 0.1,4 -3.2,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8128_"
            d="m 963.1,509.3 c -4.1,-0.1 -7.2,-3.3 -7.1,-7.4 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 z"
          />
          <path
            id="XMLID_8127_"
            d="m 939.2,494.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.1 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8126_"
            d="m 922.4,501.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8125_"
            d="m 898.4,502.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.4 -6.9,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8116_"
            d="m 874.4,501.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7.1,-2.8 -7.3,-6.7 -0.2,-4.1 2.8,-7.5 6.8,-7.7 4.1,-0.3 7.6,3 7.7,7 z"
          />
          <path
            id="XMLID_8082_"
            d="m 1083.2,677.3 c -4.1,0 -7.2,-3.2 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.1 3.9,-0.1 7.4,3.2 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_310_"
            d="m 1083.2,703.5 c -4.1,0 -7.2,-3.2 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.1 3.9,-0.1 7.4,3.2 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_309_"
            d="m 1107.3,677.3 c -4.1,0 -7.2,-3.2 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.1 3.9,-0.1 7.4,3.2 7.5,7.1 0.1,4 -3.1,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_307_"
            d="m 1083.2,653.4 c -4.1,0 -7.2,-3.2 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.1 3.9,-0.1 7.4,3.2 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_8074_"
            d="m 1162.4,477.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8062_"
            d="m 1138.4,478.1 c 0,4 -3.3,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-7 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8061_"
            d="m 1114.4,478 c 0,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.1,-6.9 -0.1,-4.1 2.9,-7.5 7,-7.6 3.9,-0.1 7.4,3.2 7.4,7.2 z"
          />
          <path
            id="XMLID_283_"
            d="m 1114.4,502 c 0,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.1,-6.9 -0.1,-4.1 2.9,-7.5 7,-7.6 3.9,-0.1 7.4,3.2 7.4,7.2 z"
          />
          <path
            id="XMLID_284_"
            d="m 1138.4,502 c 0,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.1,-6.9 -0.1,-4.1 2.9,-7.5 7,-7.6 3.9,-0.1 7.4,3.2 7.4,7.2 z"
          />
          <path
            id="XMLID_286_"
            d="m 1186.4,502 c 0,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.1,-6.9 -0.1,-4.1 2.9,-7.5 7,-7.6 3.9,-0.1 7.4,3.2 7.4,7.2 z"
          />
          <path
            id="XMLID_8060_"
            d="m 1090.4,478.2 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.1 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.9 4,0.2 7.3,3.6 7.2,7.5 z"
          />
          <path
            id="XMLID_8059_"
            d="m 1066.4,478.3 c -0.1,4 -3.5,7.1 -7.6,7 -4.1,-0.2 -7,-3.5 -6.8,-7.6 0.2,-3.8 3.5,-6.9 7.3,-6.8 3.9,0 7.2,3.5 7.1,7.4 z"
          />
          <path
            id="XMLID_8058_"
            d="m 1035,485.3 c -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.3,3.4 7.3,7.3 0,4 -3.4,7.2 -7.4,7.2 z"
          />
          <path
            id="XMLID_8056_"
            d="m 1011,485.3 c -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.3,3.4 7.3,7.3 0,4 -3.4,7.2 -7.4,7.2 z"
          />
          <path
            id="XMLID_8054_"
            d="m 987.1,470.8 c 4,0 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.2,-4.1 2.9,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8044_"
            d="m 970.4,477.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8043_"
            d="m 946.4,477.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8042_"
            d="m 915.3,470.8 c 4,0.1 7.3,3.6 7.1,7.5 -0.2,4 -3.6,7.1 -7.6,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.2,-3.8 3.5,-6.8 7.2,-6.7 z"
          />
          <path
            id="XMLID_8041_"
            d="m 890.9,470.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_7979_"
            d="m 982.3,586.4 c 0.1,1.5 -0.5,3.1 -2.2,4.1 -0.5,0.3 -1.1,0.7 -1.5,1.2 -2.2,2.1 -4.6,2.1 -7,0.6 -2.6,-1.5 -3.8,-3.9 -3.6,-6.8 0.3,-3.9 3.6,-6.7 7.5,-6.6 3.8,0.1 6.8,3.2 6.8,7.5 z"
          />
        </g>
        <g id="russia" fill={region === "russia" ? "#244588" : undefined}>
          <path
            id="XMLID_268_"
            d="m 1011.1,173.3 c -4.1,-0.1 -7.2,-3.3 -7.1,-7.4 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_8853_"
            d="m 1150.3,394.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_8852_"
            d="m 1126.3,394 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_8850_"
            d="m 1095.4,401.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 3.9,0 7,2.9 7.2,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8846_"
            d="m 1078.3,394.3 c -0.1,4.1 -3.4,7.2 -7.5,7 -3.8,-0.2 -6.9,-3.4 -6.9,-7.2 0,-4 3.3,-7.3 7.4,-7.2 4.1,0.1 7.1,3.2 7,7.4 z"
          />
          <path
            id="XMLID_8845_"
            d="m 1054.3,394.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8836_"
            d="m 951.3,290.9 c 4.1,0.1 7.1,3.3 6.9,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.4,-3.3 -7.3,-7.3 0.1,-4 3.5,-7.2 7.5,-7.1 z"
          />
          <path
            id="XMLID_8829_"
            d="m 999,290.9 c 4.1,-0.1 7.3,3 7.3,7.1 0,4 -2.9,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8828_"
            d="m 1023,290.9 c 4.1,-0.1 7.3,3 7.3,7.2 0,4 -2.9,7.1 -6.7,7.2 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8826_"
            d="m 1030.3,394.2 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.1 -6.9,-3.3 -7,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 4.1,0 7.2,3.2 7.1,7.3 z"
          />
          <path
            id="XMLID_8825_"
            d="m 1054.3,298.3 c -0.1,4.1 -3.4,7.2 -7.4,7 -3.8,-0.2 -6.9,-3.4 -7,-7.1 0,-4 3.3,-7.3 7.3,-7.3 4.1,0 7.2,3.2 7.1,7.4 z"
          />
          <path
            id="XMLID_8824_"
            d="m 999,386.9 c 4.1,-0.1 7.3,3 7.3,7.1 0,4 -2.9,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8820_"
            d="m 982.3,393.8 c 0.1,4.2 -2.9,7.4 -7,7.5 -4,0 -7.4,-3.2 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.1,-0.2 7.4,2.8 7.5,6.9 z"
          />
          <path
            id="XMLID_8817_"
            d="m 958.3,394 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,-0.1 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_8809_"
            d="m 934.3,394.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8807_"
            d="m 1071.4,305.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 3.9,0 7,2.9 7.2,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8806_"
            d="m 1102.3,298.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_271_"
            d="m 1102.3,274.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8805_"
            d="m 1119.4,305.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -6.9,7.5 z"
          />
          <path
            id="XMLID_274_"
            d="m 1143.4,305.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.2,4.2 -2.8,7.4 -6.9,7.5 z"
          />
          <path
            id="XMLID_272_"
            d="m 1119.4,281.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -6.9,7.5 z"
          />
          <path
            id="XMLID_8804_"
            d="m 910.3,394.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8801_"
            d="m 1166.9,290.9 c 4.1,-0.1 7.3,3 7.4,7.1 0,4 -2.8,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-3.9 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8800_"
            d="m 1191.2,305.3 c -4,0 -7.4,-3.3 -7.4,-7.2 0,-4 3.4,-7.2 7.4,-7.2 3.9,0.1 6.9,3 7,7 0.2,4.1 -2.9,7.4 -7,7.4 z"
          />
          <path
            id="XMLID_8799_"
            d="m 1222.3,298 c 0,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,3 7.4,7.1 z"
          />
          <path
            id="XMLID_8740_"
            d="m 831.2,506.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -7,7 -4,0.1 -7.4,-3.2 -7.4,-7.2 -0.1,-3.9 3.3,-7.2 7.3,-7.2 z"
          />
          <path
            id="XMLID_8739_"
            d="m 814.3,514 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_8735_"
            d="m 1174.3,442.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8709_"
            d="m 895.9,345.4 c 0.5,-4 4,-6.9 8,-6.5 4,0.4 6.7,3.9 6.3,8.1 -0.4,3.8 -3.8,6.6 -7.7,6.3 -3.9,-0.3 -7,-4 -6.6,-7.9 z"
          />
          <path
            id="XMLID_8708_"
            d="m 951.4,353.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -6.9,7.5 z"
          />
          <path
            id="XMLID_8707_"
            d="m 982.3,346.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8706_"
            d="m 999.2,338.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -6.9,7 -4,0.1 -7.4,-3.2 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 z"
          />
          <path
            id="XMLID_8705_"
            d="m 1030.3,345.9 c 0.1,4.2 -3,7.4 -7,7.4 -4,0 -7.4,-3.3 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.2,-0.2 7.4,2.9 7.5,7 z"
          />
          <path
            id="XMLID_8704_"
            d="m 1047.1,353.3 c -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-4 3.5,-7.2 7.5,-7 3.9,0.1 6.8,3.1 6.9,7.1 0,4.1 -3.1,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_8686_"
            d="m 1279.9,369.4 c 0.5,-4 4,-6.9 8,-6.5 4,0.4 6.7,3.9 6.3,8.1 -0.4,3.8 -3.8,6.6 -7.7,6.3 -3.9,-0.3 -7,-4 -6.6,-7.9 z"
          />
          <path
            id="XMLID_8679_"
            d="m 1214.9,362.9 c 4.1,-0.1 7.3,3 7.4,7.1 0,4 -2.8,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-3.9 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_280_"
            d="m 1238.9,362.9 c 4.1,-0.1 7.3,3 7.4,7.1 0,4 -2.8,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-3.9 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8678_"
            d="m 1047.3,233.3 c -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.2,4.3 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8677_"
            d="m 1191.3,377.3 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -7,7.5 z"
          />
          <path
            id="XMLID_8675_"
            d="m 1078.3,346.3 c -0.1,4.1 -3.4,7.2 -7.4,7 -3.8,-0.2 -6.9,-3.4 -7,-7.1 0,-4 3.3,-7.3 7.3,-7.3 4.1,0 7.2,3.2 7.1,7.4 z"
          />
          <path
            id="XMLID_8670_"
            d="m 1095.4,338.9 c 4.1,0.1 7.1,3.3 6.9,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.3,-3.3 -7.3,-7.3 0,-4 3.4,-7.2 7.5,-7.1 z"
          />
          <path
            id="XMLID_8669_"
            d="m 1126.3,346.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_8668_"
            d="m 1167.2,377.3 c -4,0 -7.3,-3.3 -7.3,-7.3 0.1,-4 3.4,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7 0,4.2 -3.1,7.4 -7.1,7.4 z"
          />
          <path
            id="XMLID_8667_"
            d="m 1143.2,338.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -7,7 -4,0.1 -7.4,-3.2 -7.4,-7.2 -0.1,-3.9 3.3,-7.2 7.3,-7.2 z"
          />
          <path
            id="XMLID_8665_"
            d="m 1143.3,362.9 c 4.1,0.1 7.1,3.3 6.9,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.4,-3.3 -7.3,-7.3 0.1,-4 3.5,-7.2 7.5,-7.1 z"
          />
          <path
            id="XMLID_8663_"
            d="m 1174.3,346.2 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-3.8 3.4,-6.9 7.2,-6.9 4.1,-0.1 7.2,3 7.2,7.2 z"
          />
          <path
            id="XMLID_8660_"
            d="m 1126.3,369.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_8657_"
            d="m 1191.3,338.9 c 4.1,0.1 7.1,3.3 7,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.9 3.4,-7.2 7.4,-7.1 z"
          />
          <path
            id="XMLID_8653_"
            d="m 1102.3,369.9 c 0.1,4.2 -3,7.4 -7,7.4 -4,0 -7.4,-3.3 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.2,-0.2 7.4,2.9 7.5,7 z"
          />
          <path
            id="XMLID_8643_"
            d="m 1071.2,377.3 c -4,0 -7.4,-3.3 -7.4,-7.2 0,-4 3.4,-7.2 7.4,-7.2 3.9,0.1 6.9,3 7,7 0.2,4.2 -2.9,7.4 -7,7.4 z"
          />
          <path
            id="XMLID_8636_"
            d="m 999.3,257.3 c -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.2,4.3 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8635_"
            d="m 1023,242.9 c 4.1,-0.1 7.3,3 7.3,7.2 0,4 -2.9,7.1 -6.7,7.2 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_269_"
            d="m 1023,171 c 4.1,-0.1 7.3,3 7.3,7.2 0,4 -2.9,7.1 -6.7,7.2 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8634_"
            d="m 1047.1,242.9 c 4.1,0 7.2,3.1 7.2,7.3 0,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3 -7.6,-7 0,-4 3.3,-7.4 7.3,-7.4 z"
          />
          <path
            id="XMLID_8630_"
            d="m 1047.3,377.3 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -7,7.5 z"
          />
          <path
            id="XMLID_8627_"
            d="m 1030.3,369.8 c 0.1,4.2 -2.9,7.4 -7,7.5 -4,0 -7.4,-3.2 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.1,-0.2 7.4,2.8 7.5,6.9 z"
          />
          <path
            id="XMLID_8618_"
            d="m 999.4,362.9 c 4.1,0.1 7.1,3.3 6.9,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.3,-3.3 -7.3,-7.3 0,-4 3.4,-7.2 7.5,-7.1 z"
          />
          <path
            id="XMLID_8616_"
            d="m 951.4,314.9 c 4.1,0.1 7.1,3.3 6.9,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.3,-3.3 -7.3,-7.3 0,-4 3.4,-7.2 7.5,-7.1 z"
          />
          <path
            id="XMLID_8613_"
            d="m 975.4,329.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -6.9,7.5 z"
          />
          <path
            id="XMLID_8612_"
            d="m 1006.3,322 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_8611_"
            d="m 1030.3,322.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_8610_"
            d="m 1039.9,321.4 c 0.5,-4 4,-6.9 8,-6.5 4,0.4 6.7,3.9 6.3,8.1 -0.4,3.8 -3.8,6.6 -7.7,6.3 -3.9,-0.3 -7,-4 -6.6,-7.9 z"
          />
          <path
            id="XMLID_8609_"
            d="m 1071.4,329.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -6.9,7.5 z"
          />
          <path
            id="XMLID_8608_"
            d="m 982.3,369.9 c 0.1,4.2 -3,7.4 -7,7.5 -4,0 -7.4,-3.2 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.1,-0.3 7.4,2.7 7.5,6.9 z"
          />
          <path
            id="XMLID_8607_"
            d="m 1102.3,322.3 c -0.1,4.1 -3.4,7.2 -7.5,7 -3.8,-0.2 -6.9,-3.4 -6.9,-7.2 0,-4 3.3,-7.3 7.4,-7.2 4.1,0.1 7.1,3.2 7,7.4 z"
          />
          <path
            id="XMLID_8606_"
            d="m 1119.1,329.3 c -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-4 3.5,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7.1 0,4.2 -3.1,7.4 -7.2,7.4 z"
          />
          <path
            id="XMLID_8605_"
            d="m 951.4,362.9 c 4.1,0.1 7.1,3.3 6.9,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.3,-3.3 -7.3,-7.3 0,-4 3.4,-7.2 7.5,-7.1 z"
          />
          <path
            id="XMLID_8603_"
            d="m 1142.9,314.9 c 4.1,-0.1 7.3,3 7.4,7.1 0,4 -2.8,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-3.9 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8600_"
            d="m 982.3,274.2 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.1 -7,-3.3 -7,-7.1 -0.1,-4 3.2,-7.3 7.3,-7.3 4.1,0 7.1,3.2 7.1,7.3 z"
          />
          <path
            id="XMLID_8599_"
            d="m 1006.3,273.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_8598_"
            d="m 1023.2,266.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -7,7 -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-3.9 3.4,-7.2 7.4,-7.2 z"
          />
          <path
            id="XMLID_8597_"
            d="m 1071.6,267 c 3.8,0 6.8,3.1 6.7,7.1 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.1,-4 3.3,-6.8 7.7,-6.8 z"
          />
          <path
            id="XMLID_8596_"
            d="m 1174.3,321.9 c 0.1,4.2 -3,7.4 -7,7.4 -4,0 -7.4,-3.3 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.2,-0.2 7.4,2.9 7.5,7 z"
          />
          <path
            id="XMLID_8595_"
            d="m 927,377.3 c -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-4 3.6,-7.1 7.6,-6.9 3.9,0.2 6.8,3.2 6.8,7.2 0,4.1 -3.2,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_8594_"
            d="m 1191.1,329.3 c -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.5,-7.1 7.6,-7 3.9,0.2 6.8,3.2 6.8,7.2 0,4.1 -3.2,7.3 -7.2,7.2 z"
          />
          <path
            id="XMLID_8593_"
            d="m 903.1,377.3 c -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.5,-7.1 7.6,-7 3.9,0.2 6.8,3.2 6.8,7.2 0,4.1 -3.2,7.3 -7.2,7.2 z"
          />
          <path
            id="XMLID_8592_"
            d="m 1215.3,314.9 c 4.1,0.1 7.1,3.3 7,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.9 3.4,-7.2 7.4,-7.1 z"
          />
          <path
            id="XMLID_8591_"
            d="m 1238.9,314.9 c 4.1,-0.1 7.3,3 7.4,7.1 0,4 -2.8,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-3.9 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8590_"
            d="m 1263.3,314.9 c 4.1,0.1 7.1,3.3 7,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.9 3.4,-7.2 7.4,-7.1 z"
          />
          <path
            id="XMLID_277_"
            d="m 1287.3,314.9 c 4.1,0.1 7.1,3.3 7,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.9 3.4,-7.2 7.4,-7.1 z"
          />
          <path
            id="XMLID_8589_"
            d="m 1318.3,322.3 c -0.1,4.1 -3.4,7.2 -7.4,7 -4,-0.2 -7.2,-3.7 -7,-7.6 0.2,-3.8 3.4,-6.8 7.3,-6.8 3.8,0 7.2,3.5 7.1,7.4 z"
          />
          <path
            id="XMLID_8587_"
            d="m 1222.3,346.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_8586_"
            d="m 1239.2,353.3 c -4,0 -7.3,-3.3 -7.3,-7.3 0.1,-4 3.4,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7 0,4.2 -3.1,7.4 -7.1,7.4 z"
          />
          <path
            id="XMLID_8583_"
            d="m 1270.3,346.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_279_"
            d="m 1270.3,394.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_8579_"
            d="m 1286.9,338.9 c 4.1,-0.1 7.3,3 7.4,7.1 0,4 -2.8,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-3.9 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8577_"
            d="m 1311.3,338.9 c 4.1,0.1 7.1,3.2 7,7.4 -0.1,3.9 -3.1,6.9 -7,7 -4,0 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.4,-7.2 z"
          />
          <path
            id="XMLID_278_"
            d="m 1335.2,338.9 c 4.1,0.1 7.1,3.2 7,7.4 -0.1,3.9 -3.1,6.9 -7,7 -4,0 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.4,-7.2 z"
          />
          <path
            id="XMLID_8558_"
            d="m 1143.1,434.9 c 4.1,0 7.2,3.1 7.2,7.3 -0.1,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8553_"
            d="m 1167.1,401.3 c -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.5,-7.1 7.6,-7 3.9,0.2 6.8,3.2 6.8,7.2 0,4.1 -3.2,7.3 -7.2,7.2 z"
          />
          <path
            id="XMLID_8544_"
            d="m 1246.3,418.3 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.2 -6.9,-3.3 -7,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 4.1,-0.1 7.2,3.1 7.1,7.3 z"
          />
          <path
            id="XMLID_8534_"
            d="m 958.3,418 c 0,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_8533_"
            d="m 975,410.9 c 4.1,0 7.3,3.1 7.2,7.2 0,3.9 -2.9,7 -6.8,7.2 -4,0.2 -7.5,-3 -7.6,-7 -0.1,-3.9 3.2,-7.4 7.2,-7.4 z"
          />
          <path
            id="XMLID_8532_"
            d="m 1006.3,418.3 c -0.1,4.1 -3.4,7.2 -7.4,7 -3.8,-0.2 -6.9,-3.4 -7,-7.1 0,-4 3.3,-7.3 7.3,-7.3 4.1,0 7.2,3.2 7.1,7.4 z"
          />
          <path
            id="XMLID_8529_"
            d="m 1078.3,418.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8527_"
            d="m 1126.3,418.2 c 0,4.1 -3.3,7.3 -7.4,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 4.2,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_8526_"
            d="m 1143.1,410.9 c 4.1,0 7.2,3.1 7.2,7.3 -0.1,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8416_"
            d="m 987.4,317.3 c -4.1,0.1 -7.3,-3 -7.4,-7.1 0,-4.1 3.2,-7.4 7.2,-7.4 3.8,0 7,3.1 7.2,6.9 0.2,4 -3,7.4 -7,7.6 z"
          />
          <path
            id="XMLID_266_"
            d="m 963.4,317.3 c -4.1,0.1 -7.3,-3 -7.4,-7.1 0,-4.1 3.2,-7.4 7.2,-7.4 3.8,0 7,3.1 7.2,6.9 0.2,4 -3,7.4 -7,7.6 z"
          />
          <path
            id="XMLID_8415_"
            d="m 1018.4,310.2 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.1 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.9 4,0.2 7.3,3.6 7.2,7.5 z"
          />
          <path
            id="XMLID_8414_"
            d="m 1034.9,302.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8413_"
            d="m 1066.4,310.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8412_"
            d="m 1083.4,317.3 c -4.1,0.1 -7.3,-3 -7.4,-7.1 0,-4.1 3.2,-7.4 7.2,-7.4 3.8,0 7,3.1 7.2,6.9 0.2,4 -3,7.4 -7,7.6 z"
          />
          <path
            id="XMLID_8411_"
            d="m 1107.1,302.8 c 4,0 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.2,-4.1 2.9,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8410_"
            d="m 1138.4,310.1 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8409_"
            d="m 1155.1,317.3 c -4.1,-0.1 -7.2,-3.3 -7.1,-7.4 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 z"
          />
          <path
            id="XMLID_8408_"
            d="m 1179.3,317.3 c -4.1,0.1 -7.3,-3.1 -7.3,-7.2 0,-3.9 3,-7 6.8,-7.2 3.9,-0.2 7.4,3.1 7.6,7 0.1,4 -3.1,7.3 -7.1,7.4 z"
          />
          <path
            id="XMLID_275_"
            d="m 1179.3,293.3 c -4.1,0.1 -7.3,-3.1 -7.3,-7.2 0,-3.9 3,-7 6.8,-7.2 3.9,-0.2 7.4,3.1 7.6,7 0.1,3.9 -3.1,7.3 -7.1,7.4 z"
          />
          <path
            id="XMLID_276_"
            d="m 1203.3,293.3 c -4.1,0.1 -7.3,-3.1 -7.3,-7.2 0,-3.9 3,-7 6.8,-7.2 3.9,-0.2 7.4,3.1 7.6,7 0.1,3.9 -3.1,7.3 -7.1,7.4 z"
          />
          <path
            id="XMLID_8407_"
            d="m 1210.4,310 c 0,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.1,-6.9 -0.1,-4.1 2.9,-7.5 7,-7.6 3.9,-0.1 7.4,3.2 7.4,7.2 z"
          />
          <path
            id="XMLID_8406_"
            d="m 1227.4,302.8 c 4,0.2 7.2,3.7 7,7.6 -0.2,4 -3.6,7.1 -7.7,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.3,-3.9 3.6,-6.9 7.4,-6.8 z"
          />
          <path
            id="XMLID_8405_"
            d="m 1154.9,446.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_263_"
            d="m 922.4,334 c 0,4 -3.3,7.3 -7.3,7.2 -3.9,0 -7,-3 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,0 7.4,3.4 7.4,7.3 z"
          />
          <path
            id="XMLID_264_"
            d="m 922.4,309.9 c 0,4 -3.3,7.3 -7.3,7.2 -3.9,0 -7,-3 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,0 7.4,3.4 7.4,7.3 z"
          />
          <path
            id="XMLID_265_"
            d="m 922.4,286.2 c 0,4 -3.3,7.3 -7.3,7.2 -3.9,0 -7,-3 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,0 7.4,3.3 7.4,7.3 z"
          />
          <path
            id="XMLID_8371_"
            d="m 962.9,326.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8370_"
            d="m 994.4,334.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.4 -6.9,-7.5 0.2,-3.9 3.4,-6.9 7.2,-6.9 3.9,0 7.3,3.4 7.2,7.3 z"
          />
          <path
            id="XMLID_8369_"
            d="m 1004,333.7 c 0.2,-3.9 3.3,-6.9 7.2,-6.9 4,0 7.3,3.4 7.3,7.4 -0.1,4 -3.4,7.2 -7.5,7.1 -4.2,-0.2 -7.2,-3.4 -7,-7.6 z"
          />
          <path
            id="XMLID_8368_"
            d="m 1035,326.8 c 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 -3.9,0 -6.9,-3 -7.1,-6.9 -0.1,-4.1 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8367_"
            d="m 1066.4,333.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8366_"
            d="m 1090.4,334.2 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.1 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.9 4,0.2 7.3,3.6 7.2,7.5 z"
          />
          <path
            id="XMLID_8365_"
            d="m 1107.2,326.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.2 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8364_"
            d="m 1131.3,341.3 c -4.1,0.1 -7.3,-3 -7.3,-7.2 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,4 -3.1,7.4 -7.1,7.5 z"
          />
          <path
            id="XMLID_8363_"
            d="m 1162.4,334.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8362_"
            d="m 1179,341.3 c -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.3,3.4 7.3,7.3 0,4 -3.4,7.2 -7.4,7.2 z"
          />
          <path
            id="XMLID_8361_"
            d="m 1203.4,326.8 c 4,0.2 7.2,3.7 7,7.6 -0.2,4 -3.6,7.1 -7.7,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.3,-3.9 3.6,-6.9 7.4,-6.8 z"
          />
          <path
            id="XMLID_8360_"
            d="m 1234.4,334.3 c -0.1,4 -3.5,7.1 -7.6,7 -4.1,-0.2 -7,-3.5 -6.8,-7.6 0.2,-3.8 3.5,-6.9 7.3,-6.8 3.9,0 7.2,3.5 7.1,7.4 z"
          />
          <path
            id="XMLID_8359_"
            d="m 1258.4,334.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8358_"
            d="m 1274.9,326.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8357_"
            d="m 1299.1,326.8 c 4,0 7.4,3.3 7.3,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.1,-4.1 3,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8356_"
            d="m 1323.3,341.3 c -4.2,0 -7.3,-3.1 -7.3,-7.2 0,-3.9 3,-7 6.8,-7.2 3.9,-0.2 7.4,3.1 7.6,7 0.1,4 -3.1,7.3 -7.1,7.4 z"
          />
          <path
            id="XMLID_8326_"
            d="m 915.2,365.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0,-3.9 3,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.4 z"
          />
          <path
            id="XMLID_8325_"
            d="m 946.4,358.5 c -0.3,4 -3.8,7 -7.8,6.7 -4.1,-0.3 -6.9,-3.6 -6.6,-7.8 0.3,-3.8 3.7,-6.8 7.4,-6.6 4.1,0.3 7.3,3.8 7,7.7 z"
          />
          <path
            id="XMLID_8324_"
            d="m 970.4,358.3 c -0.1,4 -3.5,7.1 -7.6,7 -4.1,-0.2 -7,-3.5 -6.8,-7.6 0.2,-3.8 3.5,-6.9 7.3,-6.8 3.9,0 7.2,3.5 7.1,7.4 z"
          />
          <path
            id="XMLID_8323_"
            d="m 994.4,358.1 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8322_"
            d="m 1018.4,358.3 c -0.1,4 -3.5,7.1 -7.6,7 -4.1,-0.2 -7,-3.5 -6.8,-7.6 0.2,-3.8 3.5,-6.9 7.3,-6.8 3.9,0 7.2,3.5 7.1,7.4 z"
          />
          <path
            id="XMLID_8321_"
            d="m 1042.4,358.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8320_"
            d="m 1059.1,365.3 c -4.1,0 -7.2,-3.2 -7.1,-7.4 0.1,-3.9 3.1,-7 7,-7.1 4,-0.1 7.4,3.2 7.4,7.2 0,4 -3.2,7.3 -7.3,7.3 z"
          />
          <path
            id="XMLID_8319_"
            d="m 1083.4,350.8 c 4,0.2 7.2,3.7 7,7.6 -0.2,4 -3.6,7.1 -7.7,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.3,-3.9 3.6,-6.9 7.4,-6.8 z"
          />
          <path
            id="XMLID_8318_"
            d="m 1114.4,358 c 0,4 -3.3,7.3 -7.3,7.2 -3.9,0 -7,-3 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.5 3.9,-0.1 7.4,3.3 7.4,7.2 z"
          />
          <path
            id="XMLID_8317_"
            d="m 1138.4,358.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8316_"
            d="m 1155.1,365.3 c -4.1,-0.1 -7.2,-3.3 -7.1,-7.4 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 z"
          />
          <path
            id="XMLID_8315_"
            d="m 1178.9,350.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8314_"
            d="m 1203.4,350.8 c 4,0.2 7.2,3.7 7,7.6 -0.2,4 -3.6,7.1 -7.7,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.3,-3.9 3.6,-6.9 7.4,-6.8 z"
          />
          <path
            id="XMLID_8313_"
            d="m 1234.4,358.1 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0.1 7.4,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_281_"
            d="m 1234.4,382.2 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.4,3.4 7.3,7.4 z"
          />
          <path
            id="XMLID_8312_"
            d="m 1251.3,350.8 c 4,0.1 7.3,3.6 7.1,7.5 -0.2,4 -3.6,7.1 -7.6,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.2,-3.8 3.5,-6.8 7.2,-6.7 z"
          />
          <path
            id="XMLID_8311_"
            d="m 1282.4,358.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8310_"
            d="m 1292,358.1 c 0,-4.1 3.2,-7.4 7.2,-7.3 4,0.1 7.3,3.5 7.2,7.4 -0.1,3.8 -3.2,6.9 -7.1,7 -4.1,0.2 -7.3,-2.9 -7.3,-7.1 z"
          />
          <path
            id="XMLID_8292_"
            d="m 898.4,382 c 0,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.1,-6.9 -0.1,-4.1 2.9,-7.5 7,-7.6 3.9,-0.1 7.4,3.2 7.4,7.2 z"
          />
          <path
            id="XMLID_8291_"
            d="m 922.4,382.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8290_"
            d="m 946.4,381.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8289_"
            d="m 963.1,389.3 c -4.1,-0.1 -7.2,-3.3 -7.1,-7.4 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 z"
          />
          <path
            id="XMLID_8288_"
            d="m 994.4,382.3 c -0.1,4 -3.5,7.1 -7.6,7 -4.1,-0.2 -7,-3.5 -6.8,-7.6 0.2,-3.8 3.5,-6.9 7.3,-6.8 3.9,0 7.2,3.5 7.1,7.4 z"
          />
          <path
            id="XMLID_8287_"
            d="m 1018.4,382.3 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.2 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.8 4,0 7.3,3.4 7.2,7.4 z"
          />
          <path
            id="XMLID_8286_"
            d="m 1034.9,374.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8285_"
            d="m 1059.4,389.3 c -4.1,0.1 -7.3,-3 -7.4,-7.1 0,-4.1 3.2,-7.4 7.2,-7.4 3.8,0 7,3.1 7.2,6.9 0.2,4 -3,7.4 -7,7.6 z"
          />
          <path
            id="XMLID_8284_"
            d="m 1076,382.1 c 0,-4.1 3.2,-7.4 7.2,-7.3 4,0.1 7.3,3.5 7.2,7.4 -0.1,3.8 -3.2,6.9 -7.1,7 -4.1,0.2 -7.3,-2.9 -7.3,-7.1 z"
          />
          <path
            id="XMLID_8283_"
            d="m 1107.1,389.3 c -4.1,0 -7.2,-3.2 -7.1,-7.4 0.1,-3.9 3.1,-7 7,-7.1 4,-0.1 7.4,3.2 7.4,7.2 0,4 -3.2,7.3 -7.3,7.3 z"
          />
          <path
            id="XMLID_8282_"
            d="m 1131,389.3 c -4.1,-0.1 -7.2,-3.3 -7,-7.4 0.1,-3.9 3.2,-7 7.1,-7 4,0 7.4,3.3 7.3,7.3 0,3.9 -3.3,7.1 -7.4,7.1 z"
          />
          <path
            id="XMLID_8281_"
            d="m 1148,381.7 c 0.2,-3.9 3.3,-6.9 7.2,-6.9 4,0 7.3,3.4 7.3,7.4 -0.1,4 -3.4,7.2 -7.5,7.1 -4.2,-0.2 -7.2,-3.4 -7,-7.6 z"
          />
          <path
            id="XMLID_8280_"
            d="m 1186.4,382.1 c 0,4 -3.3,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-7 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8279_"
            d="m 1210.4,382.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4.1,-0.1 7.5,3.3 7.4,7.3 z"
          />
          <path
            id="XMLID_8278_"
            d="m 1282.4,381.9 c 0.1,4 -3.2,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8277_"
            d="m 1130.9,422.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_282_"
            d="m 1154.9,422.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8271_"
            d="m 980,429.7 c 0.2,-3.9 3.3,-6.9 7.2,-6.9 4,0 7.3,3.4 7.3,7.4 -0.1,4 -3.4,7.2 -7.5,7.1 -4.2,-0.2 -7.2,-3.4 -7,-7.6 z"
          />
          <path
            id="XMLID_8266_"
            d="m 1207.9,393.9 c 0.1,-4 3.5,-7.2 7.5,-7 4.1,0.1 7,3.3 6.9,7.5 -0.1,3.7 -3.2,7 -6.6,6.8 -4.9,-0.3 -7.5,-2.4 -7.8,-7.3 z"
          />
          <path
            id="XMLID_8243_"
            d="m 898.4,406.1 c 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-6.9 -0.1,-4.1 3,-7.4 7.1,-7.5 3.9,-0.1 7.3,3.2 7.3,7.2 z"
          />
          <path
            id="XMLID_8242_"
            d="m 922.4,405.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8241_"
            d="m 946.4,405.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8240_"
            d="m 970.4,406 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4,-0.2 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8239_"
            d="m 994.4,406.2 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.1 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.9 4,0.2 7.3,3.6 7.2,7.5 z"
          />
          <path
            id="XMLID_8238_"
            d="m 1018.4,406 c 0.1,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4.1,-0.2 7.5,3.1 7.6,7.1 z"
          />
          <path
            id="XMLID_8237_"
            d="m 1042.4,406.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.4 -6.9,-7.5 0.2,-3.9 3.4,-6.9 7.2,-6.9 3.9,0 7.3,3.4 7.2,7.3 z"
          />
          <path
            id="XMLID_8236_"
            d="m 1066.4,405.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7.1,-2.8 -7.2,-6.7 -0.2,-4.1 2.8,-7.5 6.8,-7.7 4,-0.3 7.5,3 7.6,7 z"
          />
          <path
            id="XMLID_8235_"
            d="m 1083,413.3 c -4.1,-0.1 -7.2,-3.3 -7,-7.4 0.1,-3.9 3.2,-7 7.1,-7 4,0 7.4,3.3 7.3,7.3 0,3.9 -3.3,7.1 -7.4,7.1 z"
          />
          <path
            id="XMLID_8234_"
            d="m 1114.4,406.3 c -0.1,4 -3.5,7.1 -7.6,7 -4.1,-0.2 -7,-3.5 -6.8,-7.6 0.2,-3.8 3.5,-6.9 7.3,-6.8 3.9,0 7.2,3.5 7.1,7.4 z"
          />
          <path
            id="XMLID_8233_"
            d="m 1138.4,406.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8232_"
            d="m 1162.4,406.1 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0.1 7.4,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8187_"
            d="m 819.3,533.3 c -4.1,0.1 -7.3,-3 -7.3,-7.2 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,4 -3.1,7.4 -7.1,7.5 z"
          />
          <path
            id="XMLID_8115_"
            d="m 850.4,501.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8091_"
            d="m 1042.4,238.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_267_"
            d="m 1042.4,214.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_270_"
            d="m 1042.4,190.3 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,0 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8081_"
            d="m 1066.4,238.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.4 -6.9,-7.5 0.2,-3.9 3.4,-6.9 7.2,-6.9 3.9,0 7.3,3.4 7.2,7.3 z"
          />
          <path
            id="XMLID_8076_"
            d="m 1011.1,269.3 c -4.1,-0.1 -7.2,-3.3 -7.1,-7.4 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 z"
          />
          <path
            id="XMLID_8075_"
            d="m 1035.3,269.3 c -4.1,0.1 -7.3,-3 -7.3,-7.2 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,4 -3.1,7.4 -7.1,7.5 z"
          />
          <path
            id="XMLID_8022_"
            d="m 994.4,286.5 c -0.3,4 -3.8,7 -7.8,6.7 -4.1,-0.3 -6.9,-3.6 -6.6,-7.8 0.3,-3.8 3.7,-6.8 7.4,-6.6 4.1,0.3 7.3,3.8 7,7.7 z"
          />
          <path
            id="XMLID_8021_"
            d="m 1011.1,293.3 c -4.1,-0.1 -7.2,-3.3 -7.1,-7.4 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 z"
          />
          <path
            id="XMLID_8020_"
            d="m 1042.4,286.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8019_"
            d="m 1066.4,286.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8018_"
            d="m 1083.4,278.8 c 4,0.2 7.2,3.7 7,7.6 -0.2,4 -3.6,7.1 -7.7,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.3,-3.9 3.6,-6.9 7.4,-6.8 z"
          />
          <path
            id="XMLID_8017_"
            d="m 1114.4,285.9 c 0.1,4 -3.2,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_273_"
            d="m 1138.4,285.9 c 0.1,4 -3.2,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_7974_"
            d="m 1251.3,413.3 c -4.1,0.1 -7.3,-3 -7.3,-7.1 0,-4.1 3.2,-7.4 7.2,-7.3 1.8,0.1 3.7,0.4 4.7,1.9 1.1,1.6 2.1,3.6 2.4,5.6 0.5,3.4 -3.2,6.8 -7,6.9 z"
          />
          <path
            id="XMLID_7970_"
            d="m 1167.4,473.3 c -3.7,0.1 -6.8,-2.5 -7.4,-6.2 -0.6,-3.3 1.6,-6.8 4.8,-7.9 3.3,-1.1 6.9,0.3 8.6,3.3 0.1,0.1 0.2,0.3 0.2,0.4 0.8,3 -0.8,5.6 -1.8,8.2 -0.5,1.4 -2.4,2.2 -4.4,2.2 z"
          />
          <path
            id="XMLID_8803_"
            d="m 879,401.3 c -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.6,-7.1 7.6,-7 3.9,0.2 6.8,3.2 6.8,7.2 0.1,4.1 -3.1,7.3 -7.2,7.2 z"
          />
          <path
            id="XMLID_8795_"
            d="m 862.3,394.2 c 0,4.1 -3.3,7.3 -7.4,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 4.2,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_8791_"
            d="m 838.3,393.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_8790_"
            d="m 814.3,394.3 c -0.1,4.1 -3.4,7.2 -7.4,7 -3.8,-0.2 -6.9,-3.4 -7,-7.1 0,-4 3.3,-7.3 7.3,-7.3 4.1,0 7.2,3.2 7.1,7.4 z"
          />
          <path
            id="XMLID_8738_"
            d="m 838.3,466 c 0,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_8733_"
            d="m 855.4,338.9 c 4.1,0.1 7.1,3.3 6.9,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.3,-3.3 -7.3,-7.3 0,-4 3.4,-7.2 7.5,-7.1 z"
          />
          <path
            id="XMLID_214_"
            d="m 831.4,338.9 c 4.1,0.1 7.1,3.3 6.9,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.3,-3.3 -7.3,-7.3 0,-4 3.4,-7.2 7.5,-7.1 z"
          />
          <path
            id="XMLID_8732_"
            d="m 878.9,338.9 c 4.1,-0.1 7.3,3 7.4,7.1 0,4 -2.8,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-3.9 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_262_"
            d="m 895.9,225.3 c 0.5,-4 4,-6.9 8,-6.5 4,0.4 6.7,3.9 6.3,8.1 -0.4,3.8 -3.8,6.6 -7.7,6.3 -3.9,-0.3 -7,-4 -6.6,-7.9 z"
          />
          <path
            id="XMLID_8644_"
            d="m 831.1,497.3 c -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-4 3.5,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7.1 0,4.2 -3.1,7.4 -7.2,7.4 z"
          />
          <path
            id="XMLID_8637_"
            d="m 814.3,490 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.2,-7 7,-7.1 4.2,0 7.4,3.1 7.4,7.2 z"
          />
          <path
            id="XMLID_218_"
            d="m 879.3,257.3 c -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.2,4.3 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8585_"
            d="m 886.3,370.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_8584_"
            d="m 855.2,377.3 c -4,0 -7.4,-3.3 -7.4,-7.2 0,-4 3.4,-7.2 7.4,-7.2 3.9,0.1 6.9,3 7,7 0.2,4.2 -2.9,7.4 -7,7.4 z"
          />
          <path
            id="XMLID_8582_"
            d="m 831,377.3 c -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.6,-7.1 7.6,-7 3.9,0.2 6.8,3.2 6.8,7.2 0.1,4.1 -3.1,7.3 -7.2,7.2 z"
          />
          <path
            id="XMLID_8581_"
            d="m 807.8,362.9 c 4,0.3 6.8,3.7 6.5,7.9 -0.3,3.8 -3.6,6.7 -7.5,6.5 -4,-0.2 -7.2,-3.8 -6.9,-7.7 0.3,-4 3.8,-7 7.9,-6.7 z"
          />
          <path
            id="XMLID_8576_"
            d="m 782.9,362.9 c 4.1,-0.1 7.3,3 7.4,7.1 0,4 -2.8,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-3.9 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8562_"
            d="m 831,434.9 c 4.1,-0.1 7.3,3 7.3,7.1 0,4 -2.9,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8540_"
            d="m 807,425.3 c -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-4 3.6,-7.1 7.7,-6.9 3.9,0.2 6.8,3.3 6.7,7.2 0,4.2 -3.3,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_8539_"
            d="m 838.3,418 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,-0.1 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_8538_"
            d="m 862.3,418.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8537_"
            d="m 886.3,417.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_8392_"
            d="m 843.2,461.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.4 -7.2,7.4 z"
          />
          <path
            id="XMLID_8391_"
            d="m 819.3,461.3 c -4.1,0.1 -7.3,-3 -7.3,-7.1 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,3.9 -3,7.3 -7.1,7.4 z"
          />
          <path
            id="XMLID_8373_"
            d="m 898.4,334 c 0,4 -3.3,7.3 -7.3,7.2 -3.9,0 -7,-3 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,0 7.4,3.4 7.4,7.3 z"
          />
          <path
            id="XMLID_219_"
            d="m 898.4,238 c 0,4 -3.3,7.3 -7.3,7.2 -3.9,0 -7,-3 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,0 7.4,3.4 7.4,7.3 z"
          />
          <path
            id="XMLID_215_"
            d="m 874.4,334 c 0,4 -3.3,7.3 -7.3,7.2 -3.9,0 -7,-3 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,0 7.4,3.4 7.4,7.3 z"
          />
          <path
            id="XMLID_216_"
            d="m 874.4,286 c 0,4 -3.3,7.3 -7.3,7.2 -3.9,0 -7,-3 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,0 7.4,3.4 7.4,7.3 z"
          />
          <path
            id="XMLID_217_"
            d="m 874.4,262 c 0,4 -3.3,7.3 -7.3,7.2 -3.9,0 -7,-3 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,0 7.4,3.3 7.4,7.3 z"
          />
          <path
            id="XMLID_8330_"
            d="m 818.9,350.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8329_"
            d="m 850.4,358.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.4 -6.9,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8328_"
            d="m 874.4,358.1 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8327_"
            d="m 898.4,358 c 0.1,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4.1,-0.2 7.5,3.1 7.6,7.1 z"
          />
          <path
            id="XMLID_8296_"
            d="m 795.2,389.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0,-3.9 3,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.4 z"
          />
          <path
            id="XMLID_8295_"
            d="m 819.3,389.3 c -4.1,0.1 -7.3,-3 -7.3,-7.2 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,4 -3.1,7.4 -7.1,7.5 z"
          />
          <path
            id="XMLID_8294_"
            d="m 843.2,374.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.8,-3.2 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8293_"
            d="m 867.4,389.3 c -4.1,0.1 -7.3,-3 -7.4,-7.1 0,-4.1 3.2,-7.4 7.2,-7.4 3.8,0 7,3.1 7.2,6.9 0.2,4 -3,7.4 -7,7.6 z"
          />
          <path
            id="XMLID_8264_"
            d="m 866.9,422.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8263_"
            d="m 850.4,430.3 c -0.1,4 -3.5,7.1 -7.6,7 -4.1,-0.2 -7,-3.5 -6.8,-7.6 0.2,-3.8 3.5,-6.9 7.3,-6.8 3.9,0 7.2,3.5 7.1,7.4 z"
          />
          <path
            id="XMLID_8262_"
            d="m 826.4,429.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8246_"
            d="m 826.4,406 c 0,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.1,-6.9 -0.1,-4.1 2.9,-7.5 7,-7.6 3.9,-0.1 7.4,3.2 7.4,7.2 z"
          />
          <path
            id="XMLID_8245_"
            d="m 842.6,413.2 c -4.1,-0.3 -6.9,-3.7 -6.5,-7.9 0.3,-3.8 3.7,-6.8 7.5,-6.5 4,0.3 7.2,3.8 6.9,7.7 -0.4,4.1 -3.9,7.1 -7.9,6.7 z"
          />
          <path
            id="XMLID_8244_"
            d="m 867.1,413.3 c -4.1,0 -7.2,-3.2 -7.1,-7.4 0.1,-3.9 3.1,-7 7,-7.1 4,-0.1 7.4,3.2 7.4,7.2 0,4 -3.2,7.3 -7.3,7.3 z"
          />
          <path
            id="XMLID_8114_"
            d="m 819.1,509.3 c -4.1,0 -7.2,-3.2 -7.1,-7.4 0.1,-3.9 3.1,-7 7,-7.1 4,-0.1 7.4,3.2 7.4,7.2 0,4 -3.2,7.3 -7.3,7.3 z"
          />
          <path
            id="XMLID_8038_"
            d="m 826.4,477.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_7960_"
            d="m 843,326.8 c 4.1,-0.1 7.6,3.6 7.4,7.7 -0.3,4 -3.7,7 -7.7,6.8 -4.3,-0.3 -7.2,-3.9 -6.6,-8.2 0.4,-2.8 4.2,-6.2 6.9,-6.3 z"
          />
        </g>
        <g id="europe" fill={region === "europe" ? "#244588" : undefined}>
          <path
            id="XMLID_8789_"
            d="m 783.2,386.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -7,7 -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-3.9 3.4,-7.2 7.4,-7.2 z"
          />
          <path
            id="XMLID_8782_"
            d="m 766.3,394 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.2,-7 7,-7.1 4.2,0 7.4,3.1 7.4,7.2 z"
          />
          <path
            id="XMLID_8774_"
            d="m 742.3,346.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8773_"
            d="m 742.3,394.2 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-3.8 3.4,-6.9 7.2,-6.9 4.1,-0.1 7.2,3 7.2,7.2 z"
          />
          <path
            id="XMLID_209_"
            d="m 718.3,394.2 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-3.8 3.4,-6.9 7.2,-6.9 4.1,-0.1 7.2,3 7.2,7.2 z"
          />
          <path
            id="XMLID_210_"
            d="m 694.3,394.2 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-3.8 3.4,-6.9 7.2,-6.9 4.1,-0.1 7.2,3 7.2,7.2 z"
          />
          <path
            id="XMLID_8767_"
            d="m 766.3,346.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_213_"
            d="m 789.9,346.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8750_"
            d="m 718.3,178 c 0,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_8749_"
            d="m 742.1,178.5 c 0,3.9 -3.1,6.8 -7,6.8 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.4,-7 7.6,-7 3.9,0 6.6,3.1 6.6,7.6 z"
          />
          <path
            id="XMLID_185_"
            d="m 754,190.3 c 0,3.9 -3.1,6.8 -7,6.8 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.4,-7 7.6,-7 3.9,0 6.6,3 6.6,7.6 z"
          />
          <path
            id="XMLID_186_"
            d="m 754,166.5 c 0,3.9 -3.1,6.8 -7,6.8 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.4,-7 7.6,-7 3.9,0 6.6,3 6.6,7.6 z"
          />
          <path
            id="XMLID_184_"
            d="m 730.3,190.3 c 0,3.9 -3.1,6.8 -7,6.8 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.4,-7 7.6,-7 3.9,0 6.6,3 6.6,7.6 z"
          />
          <path
            id="XMLID_187_"
            d="m 730.3,166.5 c 0,3.9 -3.1,6.8 -7,6.8 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.4,-7 7.6,-7 3.9,0 6.6,3 6.6,7.6 z"
          />
          <path
            id="XMLID_8729_"
            d="m 783.2,506.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -7,7 -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-3.9 3.4,-7.2 7.4,-7.2 z"
          />
          <path
            id="XMLID_8727_"
            d="m 639.3,521.3 c -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.2,4.3 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8703_"
            d="m 814.3,466 c 0.1,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_8702_"
            d="m 783.2,458.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -7,7 -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-3.9 3.4,-7.2 7.4,-7.2 z"
          />
          <path
            id="XMLID_8700_"
            d="m 758.9,458.9 c 4.1,-0.1 7.3,3 7.4,7.1 0,4 -2.8,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-3.9 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8698_"
            d="m 735.3,458.9 c 4.1,0.1 7.1,3.3 7,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.9 3.4,-7.2 7.4,-7.1 z"
          />
          <path
            id="XMLID_8697_"
            d="m 711.2,458.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -6.9,7 -4,0.1 -7.4,-3.2 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 z"
          />
          <path
            id="XMLID_8696_"
            d="m 687.8,458.9 c 4,0.3 6.8,3.7 6.5,7.9 -0.3,3.8 -3.6,6.7 -7.5,6.5 -4,-0.2 -7.2,-3.8 -6.9,-7.7 0.3,-4 3.8,-7 7.9,-6.7 z"
          />
          <path
            id="XMLID_204_"
            d="m 663.8,458.9 c 4,0.3 6.8,3.7 6.5,7.9 -0.3,3.8 -3.6,6.7 -7.5,6.5 -4,-0.2 -7.2,-3.8 -6.9,-7.7 0.3,-4 3.8,-7 7.9,-6.7 z"
          />
          <path
            id="XMLID_8671_"
            d="m 711.2,449.3 c -4,0 -7.3,-3.3 -7.3,-7.3 0.1,-4 3.4,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7 0,4.2 -3.1,7.4 -7.1,7.4 z"
          />
          <path
            id="XMLID_8664_"
            d="m 742.3,441.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_8662_"
            d="m 766.3,442.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8633_"
            d="m 790.3,490.2 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-3.8 3.4,-6.9 7.2,-6.9 4.1,-0.1 7.2,3 7.2,7.2 z"
          />
          <path
            id="XMLID_8632_"
            d="m 766.3,490 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_8631_"
            d="m 742.3,490 c 0,4.1 -3.2,7.4 -7.2,7.3 -4,-0.1 -7.4,-3.6 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_8623_"
            d="m 735.2,314.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -6.9,7 -4,0.1 -7.4,-3.2 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 z"
          />
          <path
            id="XMLID_8622_"
            d="m 759.2,314.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -7,7 -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-3.9 3.4,-7.2 7.4,-7.2 z"
          />
          <path
            id="XMLID_212_"
            d="m 759.2,290.4 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -7,7 -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-3.9 3.4,-7.3 7.4,-7.2 z"
          />
          <path
            id="XMLID_8621_"
            d="m 783.1,329.3 c -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-4 3.5,-7.2 7.5,-7 3.9,0.1 6.8,3.1 6.9,7.1 0,4.1 -3.1,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_8588_"
            d="m 790.3,442.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,-0.1 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8578_"
            d="m 814.3,442.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,-0.1 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8569_"
            d="m 759.4,362.9 c 4.1,0.1 7.1,3.3 6.9,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.3,-3.3 -7.3,-7.3 0,-4 3.4,-7.2 7.5,-7.1 z"
          />
          <path
            id="XMLID_8568_"
            d="m 735.4,377.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 3.9,0 7,2.9 7.2,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8560_"
            d="m 711.2,377.3 c -4,0 -7.4,-3.3 -7.4,-7.2 0,-4 3.4,-7.2 7.4,-7.2 3.9,0.1 6.9,3 7,7 0.2,4.2 -2.9,7.4 -7,7.4 z"
          />
          <path
            id="XMLID_8543_"
            d="m 735.2,410.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -7,7 -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-3.9 3.4,-7.2 7.4,-7.2 z"
          />
          <path
            id="XMLID_208_"
            d="m 711.2,410.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -7,7 -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-3.9 3.4,-7.2 7.4,-7.2 z"
          />
          <path
            id="XMLID_8542_"
            d="m 759,425.3 c -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-4 3.6,-7.1 7.6,-6.9 3.9,0.2 6.8,3.2 6.8,7.2 0,4.1 -3.2,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_8541_"
            d="m 783.4,410.9 c 4.1,0.1 7.1,3.3 6.9,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.3,-3.3 -7.3,-7.3 0,-4 3.4,-7.2 7.5,-7.1 z"
          />
          <path
            id="XMLID_8390_"
            d="m 795.3,461.3 c -4.1,0.1 -7.3,-3 -7.3,-7.2 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,4 -3.1,7.4 -7.1,7.5 z"
          />
          <path
            id="XMLID_8389_"
            d="m 771.1,461.3 c -4.1,-0.1 -7.2,-3.3 -7.1,-7.4 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 z"
          />
          <path
            id="XMLID_8381_"
            d="m 747.3,461.3 c -4.1,0.1 -7.3,-3.1 -7.3,-7.2 0,-3.9 3,-7 6.8,-7.2 3.9,-0.2 7.4,3.1 7.6,7 0.1,4 -3.1,7.3 -7.1,7.4 z"
          />
          <path
            id="XMLID_141_"
            d="m 598.3,345.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7.1,-2.8 -7.3,-6.7 -0.2,-4.1 2.8,-7.5 6.8,-7.7 4.1,-0.2 7.6,3.1 7.7,7 z"
          />
          <path
            id="XMLID_142_"
            d="m 610.4,357.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7.1,-2.8 -7.3,-6.7 -0.2,-4.1 2.8,-7.5 6.8,-7.7 4,-0.3 7.6,3 7.7,7 z"
          />
          <path
            id="XMLID_8377_"
            d="m 730.4,334.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8376_"
            d="m 740,333.7 c 0.2,-3.9 3.3,-6.9 7.2,-6.9 4,0 7.3,3.4 7.3,7.4 -0.1,4 -3.4,7.2 -7.5,7.1 -4.2,-0.2 -7.2,-3.4 -7,-7.6 z"
          />
          <path
            id="XMLID_8375_"
            d="m 771,341.3 c -4.1,-0.1 -7.2,-3.3 -7,-7.4 0.1,-3.9 3.2,-7 7.1,-7 4,0 7.4,3.3 7.3,7.3 0,3.9 -3.3,7.1 -7.4,7.1 z"
          />
          <path
            id="XMLID_8374_"
            d="m 730.4,453.9 c 0.1,4 -3.2,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8372_"
            d="m 706.4,454 c 0.1,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4.1,-0.2 7.5,3.1 7.6,7.1 z"
          />
          <path
            id="XMLID_8333_"
            d="m 730.4,358.1 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8332_"
            d="m 754.4,358.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8331_"
            d="m 778.4,358.1 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8300_"
            d="m 706.4,382.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8299_"
            d="m 723.1,374.8 c 4,0 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.2,-4.1 2.9,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8298_"
            d="m 754.4,381.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8297_"
            d="m 771.2,374.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.8,-3.2 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8261_"
            d="m 795,437.3 c -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.3,3.4 7.3,7.3 0,4 -3.4,7.2 -7.4,7.2 z"
          />
          <path
            id="XMLID_8260_"
            d="m 778.4,430.1 c 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-6.9 -0.1,-4.1 3,-7.4 7.1,-7.5 3.9,-0.1 7.3,3.2 7.3,7.2 z"
          />
          <path
            id="XMLID_8259_"
            d="m 754.4,430.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4.1,-0.1 7.5,3.3 7.4,7.3 z"
          />
          <path
            id="XMLID_8258_"
            d="m 723,437.3 c -4.1,-0.1 -7.2,-3.3 -7,-7.4 0.1,-3.9 3.2,-7 7.1,-7 4,0 7.4,3.3 7.3,7.3 0,3.9 -3.3,7.1 -7.4,7.1 z"
          />
          <path
            id="XMLID_211_"
            d="m 699,437.3 c -4.1,-0.1 -7.2,-3.3 -7,-7.4 0.1,-3.9 3.2,-7 7.1,-7 4,0 7.4,3.3 7.3,7.3 0,3.9 -3.3,7.1 -7.4,7.1 z"
          />
          <path
            id="XMLID_8250_"
            d="m 730.4,406.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8249_"
            d="m 747,413.3 c -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.3,3.4 7.3,7.3 0,4 -3.4,7.2 -7.4,7.2 z"
          />
          <path
            id="XMLID_8248_"
            d="m 778.4,406.1 c 0,4 -3.3,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-7 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8247_"
            d="m 795.2,398.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.1 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8132_"
            d="m 723.4,206.8 c 4,0.2 7.2,3.7 7,7.6 -0.2,4 -3.6,7.1 -7.7,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.3,-3.9 3.6,-6.9 7.4,-6.8 z"
          />
          <path
            id="XMLID_8113_"
            d="m 802.4,501.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_207_"
            d="m 754.4,501.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_206_"
            d="m 778.1,501.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8112_"
            d="m 651.4,494.8 c 4,0.2 7.2,3.7 7,7.6 -0.2,4 -3.6,7.1 -7.7,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.3,-3.9 3.6,-6.9 7.4,-6.8 z"
          />
          <path
            id="XMLID_199_"
            d="m 651.4,422.8 c 4,0.2 7.2,3.7 7,7.6 -0.2,4 -3.6,7.1 -7.7,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.3,-3.9 3.6,-6.9 7.4,-6.8 z"
          />
          <path
            id="XMLID_200_"
            d="m 651.4,398.8 c 4,0.2 7.2,3.7 7,7.6 -0.2,4 -3.6,7.1 -7.7,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.3,-3.9 3.6,-6.9 7.4,-6.8 z"
          />
          <path
            id="XMLID_8037_"
            d="m 802.4,478.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4.1,-0.1 7.5,3.3 7.4,7.3 z"
          />
          <path
            id="XMLID_8027_"
            d="m 771.1,470.8 c 4,0 7.4,3.3 7.3,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.1,-4.1 3,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8023_"
            d="m 754.4,478 c 0,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.1,-6.9 -0.1,-4.1 2.9,-7.5 7,-7.6 3.9,-0.1 7.4,3.2 7.4,7.2 z"
          />
          <path
            id="XMLID_8016_"
            d="m 723.1,470.8 c 4,0 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.2,-4.1 2.9,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8006_"
            d="m 699.2,485.3 c -4.6,-0.5 -7.2,-3 -7.2,-7.3 0,-4.1 3.4,-7.3 7.4,-7.1 4,0.2 7.2,3.7 7,7.6 -0.2,3.7 -3.4,6.8 -7.2,6.8 z"
          />
          <path
            id="XMLID_8005_"
            d="m 682.4,478.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_203_"
            d="m 670.3,490.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,0 7.3,3.4 7.3,7.3 z"
          />
          <path
            id="XMLID_7980_"
            d="m 747.3,317.3 c -4.1,0.1 -7.3,-3 -7.3,-7.1 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,3.9 -3,7.3 -7.1,7.4 z"
          />
          <path
            id="XMLID_7978_"
            d="m 694.3,442 c 0,4.1 -3.1,7.3 -7.2,7.3 -3.8,0 -7.2,-3.3 -7.2,-6.9 0,-4.1 3.1,-7.5 7.1,-7.5 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_201_"
            d="m 670.3,442 c 0,4.1 -3.1,7.3 -7.2,7.3 -3.8,0 -7.2,-3.3 -7.2,-6.9 0,-4.1 3.1,-7.5 7.1,-7.5 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_202_"
            d="m 646.3,442 c 0,4.1 -3.1,7.3 -7.2,7.3 -3.8,0 -7.2,-3.3 -7.2,-6.9 0,-4.1 3.1,-7.5 7.1,-7.5 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
        </g>
        <g
          id="northA"
          fill={region === "north-america" ? "#244588" : undefined}
        >
          <path
            id="XMLID_8857_"
            d="m 382.3,298.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_177_"
            d="m 394.4,286.4 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.2,0.1 7.3,3.3 7.2,7.4 z"
          />
          <path
            id="XMLID_178_"
            d="m 394.4,310 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.2,0.1 7.3,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_172_"
            d="m 406.3,298.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_173_"
            d="m 406.3,322.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_174_"
            d="m 406.3,346.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_170_"
            d="m 370.4,286.4 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.3 7.2,7.4 z"
          />
          <path
            id="XMLID_169_"
            d="m 358.3,298.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_248_"
            d="m 358.3,250.1 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.2 7.2,3.3 7.2,7.4 z"
          />
          <path
            id="XMLID_8856_"
            d="m 447.1,434.9 c 4.1,0 7.2,3.1 7.2,7.3 0,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3 -7.6,-7 0,-4 3.3,-7.4 7.3,-7.4 z"
          />
          <path
            id="XMLID_8855_"
            d="m 502.3,298.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_8854_"
            d="m 519.3,290.9 c 4.1,0.1 7.1,3.2 7,7.4 -0.1,3.9 -3.1,6.9 -7,7 -4,0 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.4,-7.2 z"
          />
          <path
            id="XMLID_8851_"
            d="m 550.3,298 c 0,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_8849_"
            d="m 295.9,369.4 c 0.5,-4 4,-6.9 8,-6.5 4,0.4 6.7,3.9 6.3,8.1 -0.4,3.8 -3.8,6.6 -7.7,6.3 -3.9,-0.3 -7,-4 -6.6,-7.9 z"
          />
          <path
            id="XMLID_151_"
            d="m 319.9,369.4 c 0.5,-4 4,-6.9 8,-6.5 4,0.4 6.7,3.9 6.3,8.1 -0.4,3.8 -3.8,6.6 -7.7,6.3 -3.9,-0.3 -7,-4 -6.6,-7.9 z"
          />
          <path
            id="XMLID_8848_"
            d="m 279.2,377.3 c -4,0 -7.4,-3.3 -7.4,-7.2 0,-4 3.4,-7.2 7.4,-7.2 3.9,0.1 6.9,3 7,7 0.2,4.2 -2.9,7.4 -7,7.4 z"
          />
          <path
            id="XMLID_8839_"
            d="m 382.3,106.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,-0.1 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8838_"
            d="m 399.4,113.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 3.9,0 7,2.9 7.2,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8837_"
            d="m 430.3,106.2 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.2 -6.9,-3.3 -7,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 4.1,0 7.2,3.2 7.1,7.3 z"
          />
          <path
            id="XMLID_8835_"
            d="m 542.8,113.3 c -3.9,-0.2 -7.2,-3.8 -6.9,-7.7 0.3,-3.8 3.5,-6.8 7.3,-6.7 4.1,0 7.1,3.2 7.1,7.4 -0.1,3.8 -3.7,7.1 -7.5,7 z"
          />
          <path
            id="XMLID_8819_"
            d="m 351.1,530.9 c 4.1,0 7.2,3.1 7.2,7.3 -0.1,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8818_"
            d="m 334.3,538 c 0,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_8816_"
            d="m 303,545.3 c -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.6,-7.1 7.6,-7 3.9,0.2 6.8,3.2 6.8,7.2 0.1,4.1 -3.1,7.3 -7.2,7.2 z"
          />
          <path
            id="XMLID_8815_"
            d="m 399.1,137.3 c -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-4 3.5,-7.2 7.5,-7 3.9,0.1 6.8,3.1 6.9,7.1 0,4.1 -3.1,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_256_"
            d="m 351.1,137.3 c -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-4 3.5,-7.2 7.5,-7 3.9,0.1 6.8,3.1 6.9,7.1 0,4.1 -3.1,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_8814_"
            d="m 471.4,137.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 3.9,0 7,2.9 7.2,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8813_"
            d="m 487.9,129.4 c 0.5,-4 4,-6.9 8,-6.5 4,0.4 6.7,3.9 6.3,8.1 -0.4,3.8 -3.8,6.6 -7.7,6.3 -3.9,-0.3 -7,-4 -6.6,-7.9 z"
          />
          <path
            id="XMLID_8812_"
            d="m 519.1,137.3 c -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.5,-7.1 7.6,-7 3.9,0.2 6.8,3.2 6.8,7.2 0,4.1 -3.2,7.3 -7.2,7.2 z"
          />
          <path
            id="XMLID_8811_"
            d="m 550.3,130.2 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.1 -7,-3.3 -7,-7.1 -0.1,-4 3.2,-7.3 7.3,-7.3 4.1,0 7.1,3.2 7.1,7.3 z"
          />
          <path
            id="XMLID_8802_"
            d="m 262.3,369.8 c 0.1,4.2 -2.9,7.4 -7,7.5 -4,0 -7.4,-3.2 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.1,-0.2 7.4,2.8 7.5,6.9 z"
          />
          <path
            id="XMLID_8794_"
            d="m 231.3,362.9 c 4.1,0.1 7.1,3.2 7,7.4 -0.1,3.9 -3.1,6.9 -7,7 -4,0 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.4,-7.2 z"
          />
          <path
            id="XMLID_8788_"
            d="m 454.3,154.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_132_"
            d="m 454.3,130.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_130_"
            d="m 430.3,154.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8787_"
            d="m 478.3,153.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_8786_"
            d="m 495.2,146.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -6.9,7 -4,0.1 -7.4,-3.2 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 z"
          />
          <path
            id="XMLID_8785_"
            d="m 526.3,153.9 c 0.1,4.2 -3,7.4 -7,7.4 -4,0 -7.4,-3.3 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.2,-0.2 7.4,2.9 7.5,7 z"
          />
          <path
            id="XMLID_8784_"
            d="m 543.4,161.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 3.9,0 7,2.9 7.2,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8783_"
            d="m 574.3,153.9 c 0.1,4.2 -3,7.4 -7,7.4 -4,0 -7.4,-3.3 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.2,-0.2 7.4,2.9 7.5,7 z"
          />
          <path
            id="XMLID_144_"
            d="m 601,153.9 c 0.1,4.2 -3,7.4 -7,7.4 -4,0 -7.4,-3.3 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.2,-0.2 7.4,2.9 7.5,7 z"
          />
          <path
            id="XMLID_180_"
            d="m 601,129.9 c 0.1,4.2 -3,7.4 -7,7.4 -4,0 -7.4,-3.3 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.2,-0.2 7.4,2.9 7.5,7 z"
          />
          <path
            id="XMLID_8778_"
            d="m 214.3,369.9 c 0.1,4.2 -3,7.4 -7,7.4 -4,0 -7.4,-3.3 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.2,-0.2 7.4,2.9 7.5,7 z"
          />
          <path
            id="XMLID_8777_"
            d="m 190.3,370.2 c 0,4.1 -3.3,7.3 -7.4,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 4.2,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_8775_"
            d="m 159.3,377.3 c -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.2,4.3 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8765_"
            d="m 399,401.3 c -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-4 3.6,-7.1 7.6,-6.9 3.9,0.2 6.8,3.2 6.8,7.2 0,4.1 -3.2,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_8764_"
            d="m 303.2,386.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -6.9,7 -4,0.1 -7.4,-3.2 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 z"
          />
          <path
            id="XMLID_8763_"
            d="m 135.3,362.9 c 4.1,0.1 7.1,3.3 7,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.9 3.4,-7.2 7.4,-7.1 z"
          />
          <path
            id="XMLID_8762_"
            d="m 286.3,394 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_8758_"
            d="m 262.3,393.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_8757_"
            d="m 439.9,177.4 c 0.5,-4 4,-6.9 8,-6.5 4,0.4 6.7,3.9 6.3,8.1 -0.4,3.8 -3.8,6.6 -7.7,6.3 -3.9,-0.3 -7,-4 -6.6,-7.9 z"
          />
          <path
            id="XMLID_8756_"
            d="m 471.2,170.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -7,7 -4,0.1 -7.4,-3.2 -7.4,-7.2 -0.1,-3.9 3.3,-7.2 7.3,-7.2 z"
          />
          <path
            id="XMLID_8755_"
            d="m 495.1,170.9 c 4.1,0 7.2,3.1 7.2,7.3 -0.1,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8754_"
            d="m 511.9,177.4 c 0.5,-4 4,-6.9 8,-6.5 4,0.4 6.7,3.9 6.3,8.1 -0.4,3.8 -3.8,6.6 -7.7,6.3 -3.9,-0.3 -7,-4 -6.6,-7.9 z"
          />
          <path
            id="XMLID_8753_"
            d="m 535.9,177.4 c 0.5,-4 4,-6.9 8,-6.5 4,0.4 6.7,3.9 6.3,8.1 -0.4,3.8 -3.8,6.6 -7.7,6.3 -3.9,-0.3 -7,-4 -6.6,-7.9 z"
          />
          <path
            id="XMLID_8752_"
            d="m 567.2,185.3 c -4,0 -7.4,-3.3 -7.4,-7.2 0,-4 3.4,-7.2 7.4,-7.2 3.9,0.1 6.9,3 7,7 0.2,4.1 -2.9,7.4 -7,7.4 z"
          />
          <path
            id="XMLID_8747_"
            d="m 231.3,401.3 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -7,7.5 z"
          />
          <path
            id="XMLID_8741_"
            d="m 207.8,386.9 c 4,0.3 6.8,3.7 6.5,7.9 -0.3,3.8 -3.6,6.7 -7.5,6.5 -4,-0.2 -7.2,-3.8 -6.9,-7.7 0.3,-4 3.8,-7 7.9,-6.7 z"
          />
          <path
            id="XMLID_8737_"
            d="m 111.8,362.9 c 4,0.3 6.8,3.7 6.5,7.9 -0.3,3.8 -3.6,6.7 -7.5,6.5 -4,-0.2 -7.2,-3.8 -6.9,-7.7 0.3,-4 3.8,-7 7.9,-6.7 z"
          />
          <path
            id="XMLID_8731_"
            d="m 519.1,266.9 c 4.1,0 7.2,3.1 7.2,7.3 -0.1,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8730_"
            d="m 183.2,401.3 c -4,0 -7.4,-3.3 -7.4,-7.2 0,-4 3.4,-7.2 7.4,-7.2 3.9,0.1 6.9,3 7,7 0.2,4.1 -2.9,7.4 -7,7.4 z"
          />
          <path
            id="XMLID_8728_"
            d="m 87,401.3 c -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-4 3.6,-7.1 7.7,-6.9 3.9,0.2 6.8,3.3 6.7,7.2 0,4.2 -3.3,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_8726_"
            d="m 375.3,506.9 c 4.1,0.1 7.1,3.3 7,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.9 3.4,-7.2 7.4,-7.1 z"
          />
          <path
            id="XMLID_8725_"
            d="m 358.3,514.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8724_"
            d="m 334.3,514 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_8723_"
            d="m 295.9,513.4 c 0.5,-4 4,-6.9 8,-6.5 4,0.4 6.7,3.9 6.3,8.1 -0.4,3.8 -3.8,6.6 -7.7,6.3 -3.9,-0.3 -7,-4 -6.6,-7.9 z"
          />
          <path
            id="XMLID_8722_"
            d="m 207.2,449.3 c -4,0 -7.4,-3.3 -7.4,-7.2 0,-4 3.4,-7.2 7.4,-7.2 3.9,0.1 6.9,3 7,7 0.2,4.1 -2.9,7.4 -7,7.4 z"
          />
          <path
            id="XMLID_8721_"
            d="m 238.3,441.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_8720_"
            d="m 454.3,202.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,-0.1 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_261_"
            d="m 430.3,202.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,-0.1 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8719_"
            d="m 478.3,202.2 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-3.8 3.4,-6.9 7.2,-6.9 4.1,-0.1 7.2,3 7.2,7.2 z"
          />
          <path
            id="XMLID_8718_"
            d="m 495.3,209.3 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -7,7.5 z"
          />
          <path
            id="XMLID_8717_"
            d="m 519,209.3 c -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-4 3.6,-7.1 7.6,-6.9 3.9,0.2 6.8,3.2 6.8,7.2 0,4.1 -3.2,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_8716_"
            d="m 543.4,209.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -6.9,7.5 z"
          />
          <path
            id="XMLID_8715_"
            d="m 567.4,209.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 3.9,0 7,2.9 7.2,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8714_"
            d="m 279.1,521.3 c -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-4 3.5,-7.2 7.5,-7 3.9,0.1 6.8,3.1 6.9,7.1 0,4.1 -3.1,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_8713_"
            d="m 255.2,506.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -7,7 -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-3.9 3.4,-7.2 7.4,-7.2 z"
          />
          <path
            id="XMLID_8711_"
            d="m 262.3,441.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_8710_"
            d="m 279.4,449.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 3.9,0 7,2.9 7.2,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8701_"
            d="m 303.3,434.9 c 4.1,0.1 7.1,3.3 7,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.9 3.4,-7.2 7.4,-7.1 z"
          />
          <path
            id="XMLID_8699_"
            d="m 87,377.3 c -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-4 3.6,-7.1 7.7,-6.9 3.9,0.2 6.8,3.3 6.7,7.2 0,4.2 -3.3,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_8695_"
            d="m 406.3,466.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,-0.1 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8694_"
            d="m 367.9,465.4 c 0.5,-4 4,-6.9 8,-6.5 4,0.4 6.7,3.9 6.3,8.1 -0.4,3.8 -3.8,6.6 -7.7,6.3 -3.9,-0.3 -7,-4 -6.6,-7.9 z"
          />
          <path
            id="XMLID_8693_"
            d="m 351.1,473.3 c -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-4 3.5,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7.1 0,4.2 -3.1,7.4 -7.2,7.4 z"
          />
          <path
            id="XMLID_8692_"
            d="m 334.3,466.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_8691_"
            d="m 303,458.9 c 4.1,-0.1 7.3,3 7.3,7.2 0,4 -2.9,7.1 -6.7,7.2 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8690_"
            d="m 334.3,442.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8689_"
            d="m 286.3,466.3 c -0.1,4.1 -3.4,7.2 -7.5,7 -3.8,-0.2 -6.9,-3.4 -6.9,-7.2 0,-4 3.3,-7.3 7.4,-7.2 4.1,0.1 7.1,3.2 7,7.4 z"
          />
          <path
            id="XMLID_8688_"
            d="m 262.3,466 c 0,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,3 7.4,7.1 z"
          />
          <path
            id="XMLID_8687_"
            d="m 430.3,226.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_8685_"
            d="m 463.9,225.4 c 0.5,-4 4,-6.9 8,-6.5 4,0.4 6.7,3.9 6.3,8.1 -0.4,3.8 -3.8,6.6 -7.7,6.3 -3.9,-0.3 -7,-4 -6.6,-7.9 z"
          />
          <path
            id="XMLID_133_"
            d="m 439.9,225.4 c 0.5,-4 4,-6.9 8,-6.5 4,0.4 6.7,3.9 6.3,8.1 -0.4,3.8 -3.8,6.6 -7.7,6.3 -3.9,-0.3 -7,-4 -6.6,-7.9 z"
          />
          <path
            id="XMLID_8684_"
            d="m 495.3,233.3 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -7,7.5 z"
          />
          <path
            id="XMLID_8683_"
            d="m 519.3,218.9 c 4.1,0.1 7.1,3.2 7,7.4 -0.1,3.9 -3.1,6.9 -7,7 -4,0 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.4,-7.2 z"
          />
          <path
            id="XMLID_8682_"
            d="m 550.3,225.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_8681_"
            d="m 574.3,226 c 0,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_8680_"
            d="m 351.4,449.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -6.9,7.5 z"
          />
          <path
            id="XMLID_8676_"
            d="m 231,473.3 c -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-4 3.6,-7.1 7.7,-6.9 3.9,0.2 6.8,3.3 6.7,7.2 0,4.2 -3.3,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_8674_"
            d="m 94.3,322.2 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.1 -6.9,-3.3 -7,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 4.1,0 7.2,3.2 7.1,7.3 z"
          />
          <path
            id="XMLID_8673_"
            d="m 111,314.9 c 4.1,-0.1 7.3,3 7.3,7.2 0,4 -2.9,7.1 -6.7,7.2 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8672_"
            d="m 135.1,314.9 c 4.1,0 7.2,3.1 7.2,7.3 -0.1,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-3.9 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8661_"
            d="m 502.3,322 c 0,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_8658_"
            d="m 519.3,314.9 c 4.1,0.1 7.1,3.2 7,7.4 -0.1,3.9 -3.1,6.9 -7,7 -4,0 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.4,-7.2 z"
          />
          <path
            id="XMLID_8642_"
            d="m 478.3,250.2 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-3.8 3.4,-6.9 7.2,-6.9 4.1,-0.1 7.2,3 7.2,7.2 z"
          />
          <path
            id="XMLID_8641_"
            d="m 502.3,250 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,-0.1 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_8640_"
            d="m 526.3,250.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_8639_"
            d="m 550.3,250 c 0.1,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_8638_"
            d="m 574.3,249.9 c 0.1,4.2 -3,7.4 -7,7.4 -4,0 -7.4,-3.2 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.1,-0.2 7.4,2.8 7.5,7 z"
          />
          <path
            id="XMLID_8629_"
            d="m 399.8,482.9 c 4,0.3 6.8,3.7 6.5,7.9 -0.3,3.8 -3.6,6.7 -7.5,6.5 -4,-0.2 -7.2,-3.8 -6.9,-7.7 0.3,-4 3.8,-7 7.9,-6.7 z"
          />
          <path
            id="XMLID_8628_"
            d="m 375.4,482.9 c 4.1,0.1 7.1,3.3 6.9,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.3,-3.3 -7.3,-7.3 0,-4 3.4,-7.2 7.5,-7.1 z"
          />
          <path
            id="XMLID_8626_"
            d="m 351.3,497.3 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -7,7.5 z"
          />
          <path
            id="XMLID_8625_"
            d="m 334.3,490.2 c 0,4.1 -3.3,7.3 -7.4,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 4.2,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_8620_"
            d="m 303.1,482.9 c 4.1,0 7.2,3.1 7.2,7.3 0,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3 -7.6,-7 0,-4 3.3,-7.4 7.3,-7.4 z"
          />
          <path
            id="XMLID_8619_"
            d="m 286.3,490.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8615_"
            d="m 262.3,490.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8614_"
            d="m 238.3,490 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_8604_"
            d="m 502.3,274.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_135_"
            d="m 478.3,274.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_8602_"
            d="m 543.2,266.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -6.9,7 -4,0.1 -7.4,-3.2 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 z"
          />
          <path
            id="XMLID_8601_"
            d="m 574.3,274 c 0,4.2 -3.1,7.4 -7.2,7.3 -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-4 3.5,-7.2 7.5,-7 3.9,0.1 6.9,3.2 6.9,7.1 z"
          />
          <path
            id="XMLID_8574_"
            d="m 87.4,353.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 3.9,0 7,2.9 7.2,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8573_"
            d="m 118.3,346.3 c -0.1,4.1 -3.4,7.2 -7.5,7 -3.8,-0.2 -6.9,-3.4 -6.9,-7.2 0,-4 3.3,-7.3 7.4,-7.2 4.1,0.1 7.1,3.2 7,7.4 z"
          />
          <path
            id="XMLID_8572_"
            d="m 142.3,346.3 c -0.1,4.1 -3.4,7.2 -7.5,7 -3.8,-0.2 -6.9,-3.4 -6.9,-7.2 0,-4 3.3,-7.3 7.4,-7.2 4.1,0.1 7.1,3.2 7,7.4 z"
          />
          <path
            id="XMLID_8571_"
            d="m 159.8,338.9 c 4,0.3 6.8,3.7 6.5,7.9 -0.3,3.8 -3.6,6.7 -7.5,6.5 -4,-0.2 -7.2,-3.8 -6.9,-7.7 0.3,-4 3.8,-7 7.9,-6.7 z"
          />
          <path
            id="XMLID_8570_"
            d="m 190.3,346.3 c -0.1,4.1 -3.4,7.2 -7.4,7 -3.8,-0.2 -6.9,-3.4 -7,-7.1 0,-4 3.3,-7.3 7.3,-7.3 4.1,0 7.2,3.2 7.1,7.4 z"
          />
          <path
            id="XMLID_8567_"
            d="m 207.4,353.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -6.9,7.5 z"
          />
          <path
            id="XMLID_8566_"
            d="m 231.3,353.3 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -7,7.5 z"
          />
          <path
            id="XMLID_155_"
            d="m 231.3,329.3 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -7,7.5 z"
          />
          <path
            id="XMLID_157_"
            d="m 243.4,317.2 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.5 -7,7.5 z"
          />
          <path
            id="XMLID_160_"
            d="m 255.2,305.3 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -7,7.5 z"
          />
          <path
            id="XMLID_161_"
            d="m 279.3,305.3 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -7,7.5 z"
          />
          <path
            id="XMLID_163_"
            d="m 303.4,281.3 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -3,7.4 -7,7.5 z"
          />
          <path
            id="XMLID_167_"
            d="m 303.4,209.5 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -3,7.5 -7,7.5 z"
          />
          <path
            id="XMLID_249_"
            d="m 375.4,209.5 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -3,7.5 -7,7.5 z"
          />
          <path
            id="XMLID_251_"
            d="m 375.4,185.3 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -3,7.4 -7,7.5 z"
          />
          <path
            id="XMLID_168_"
            d="m 291.4,197.2 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.5 -7,7.5 z"
          />
          <path
            id="XMLID_164_"
            d="m 327.8,281.3 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -7,7.5 z"
          />
          <path
            id="XMLID_158_"
            d="m 243.4,293.6 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -7,7.5 z"
          />
          <path
            id="XMLID_159_"
            d="m 267.4,293.6 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -7,7.5 z"
          />
          <path
            id="XMLID_156_"
            d="m 231.3,281.3 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -7,7.5 z"
          />
          <path
            id="XMLID_166_"
            d="m 231.3,233.3 c -4,0.1 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.3,-7.2 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -7,7.5 z"
          />
          <path
            id="XMLID_8565_"
            d="m 255.8,338.9 c 4,0.3 6.8,3.7 6.5,7.9 -0.3,3.8 -3.6,6.7 -7.5,6.5 -4,-0.2 -7.2,-3.8 -6.9,-7.7 0.3,-4 3.8,-7 7.9,-6.7 z"
          />
          <path
            id="XMLID_8564_"
            d="m 279.4,353.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-3.7 3,-7.1 6.8,-7.3 4.1,-0.2 7.5,2.7 7.6,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8563_"
            d="m 303.1,338.9 c 4.1,0 7.2,3.1 7.2,7.3 -0.1,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8561_"
            d="m 334.3,346.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_171_"
            d="m 334.3,322.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8559_"
            d="m 358.3,346 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.1 z"
          />
          <path
            id="XMLID_8557_"
            d="m 495.1,338.9 c 4.1,0 7.2,3.1 7.2,7.3 -0.1,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-3.9 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8556_"
            d="m 399.1,449.3 c -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-4 3.5,-7.2 7.5,-7 3.9,0.1 6.8,3.1 6.9,7.1 0,4.1 -3.1,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_8554_"
            d="m 502.3,370 c 0,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,3 7.4,7.1 z"
          />
          <path
            id="XMLID_8552_"
            d="m 190.3,418.2 c 0,4.1 -3.3,7.3 -7.4,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 4.2,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_8551_"
            d="m 214.3,418.2 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-3.8 3.4,-6.9 7.2,-6.9 4.1,-0.1 7.2,3 7.2,7.2 z"
          />
          <path
            id="XMLID_8550_"
            d="m 231.2,425.3 c -4,0 -7.3,-3.3 -7.3,-7.3 0.1,-4 3.4,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7 0,4.2 -3.1,7.4 -7.1,7.4 z"
          />
          <path
            id="XMLID_8549_"
            d="m 255.2,425.3 c -4,0 -7.4,-3.3 -7.4,-7.2 0,-4 3.4,-7.2 7.4,-7.2 3.9,0.1 6.9,3 7,7 0.2,4.1 -2.9,7.4 -7,7.4 z"
          />
          <path
            id="XMLID_8548_"
            d="m 279.1,425.3 c -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.5,-7.1 7.6,-7 3.9,0.2 6.8,3.2 6.8,7.2 0,4.1 -3.2,7.3 -7.2,7.2 z"
          />
          <path
            id="XMLID_8547_"
            d="m 310.3,418.2 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.1 -7,-3.3 -7,-7.1 -0.1,-4 3.2,-7.3 7.3,-7.3 4.1,0 7.1,3.2 7.1,7.3 z"
          />
          <path
            id="XMLID_8546_"
            d="m 399.2,425.3 c -4,0 -7.3,-3.3 -7.3,-7.3 0.1,-4 3.4,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7 0,4.2 -3.1,7.4 -7.1,7.4 z"
          />
          <path
            id="XMLID_8545_"
            d="m 430.3,418.2 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-3.8 3.4,-6.9 7.2,-6.9 4.1,-0.1 7.2,3 7.2,7.2 z"
          />
          <path
            id="XMLID_8487_"
            d="m 430.3,442.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8403_"
            d="m 214.3,322 c 0,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.6 3.2,-7 6.5,-7 4.7,-0.1 7.8,2.6 7.9,7 z"
          />
          <path
            id="XMLID_8388_"
            d="M 99.1,341.3 C 95,341.2 91.9,338 92,333.9 c 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 z"
          />
          <path
            id="XMLID_234_"
            d="M 99.1,317.3 C 95,317.2 91.9,314 92,309.9 c 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 z"
          />
          <path
            id="XMLID_8387_"
            d="m 123.1,326.8 c 4,0 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.2,-4.1 2.9,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8386_"
            d="m 154.4,334 c 0,4 -3.3,7.3 -7.3,7.2 -3.9,0 -7,-3 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,0 7.4,3.4 7.4,7.3 z"
          />
          <path
            id="XMLID_8385_"
            d="m 178.4,334 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4,-0.2 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8384_"
            d="m 202.4,334.5 c -0.3,4 -3.8,7 -7.8,6.7 -4.1,-0.3 -6.9,-3.6 -6.6,-7.8 0.3,-3.8 3.7,-6.8 7.4,-6.6 4.1,0.3 7.3,3.8 7,7.7 z"
          />
          <path
            id="XMLID_8383_"
            d="m 226.4,333.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8382_"
            d="m 346.4,334.3 c -0.1,4 -3.5,7.1 -7.6,6.9 -4.1,-0.2 -7,-3.5 -6.8,-7.6 0.2,-3.8 3.5,-6.9 7.2,-6.8 4,0.1 7.3,3.6 7.2,7.5 z"
          />
          <path
            id="XMLID_153_"
            d="m 322.4,334.3 c -0.1,4 -3.5,7.1 -7.6,6.9 -4.1,-0.2 -7,-3.5 -6.8,-7.6 0.2,-3.8 3.5,-6.9 7.2,-6.8 4,0.1 7.3,3.6 7.2,7.5 z"
          />
          <path
            id="XMLID_8380_"
            d="m 483.3,341.3 c -4.1,0.1 -7.3,-3 -7.3,-7.2 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,4 -3.1,7.4 -7.1,7.5 z"
          />
          <path
            id="XMLID_8379_"
            d="m 514.4,333.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8378_"
            d="m 538.4,333.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7.1,-2.8 -7.3,-6.7 -0.2,-4.1 2.8,-7.5 6.8,-7.7 4.1,-0.3 7.6,3 7.7,7 z"
          />
          <path
            id="XMLID_8355_"
            d="m 418.4,453.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_233_"
            d="m 466.6,453.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8354_"
            d="m 386.9,446.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8353_"
            d="m 363.3,461.3 c -4.1,0.1 -7.3,-3 -7.3,-7.2 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,4 -3.1,7.4 -7.1,7.5 z"
          />
          <path
            id="XMLID_8352_"
            d="m 339.3,461.3 c -4.1,0.1 -7.3,-3 -7.3,-7.1 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,3.9 -3,7.3 -7.1,7.4 z"
          />
          <path
            id="XMLID_8351_"
            d="m 322.4,454.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8350_"
            d="m 298.4,454.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8349_"
            d="m 274.4,454 c 0.1,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4.1,-0.2 7.5,3.1 7.6,7.1 z"
          />
          <path
            id="XMLID_8348_"
            d="m 243.1,446.8 c 4,0 7.4,3.3 7.3,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.1,-4.1 3,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8347_"
            d="m 226.4,454.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4.1,-0.1 7.5,3.3 7.4,7.3 z"
          />
          <path
            id="XMLID_8345_"
            d="m 106.4,358 c 0.1,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4.1,-0.2 7.5,3.1 7.6,7.1 z"
          />
          <path
            id="XMLID_8344_"
            d="m 123.2,350.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.1 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8343_"
            d="m 154.4,357.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8342_"
            d="m 178.4,358.1 c 0,4 -3.3,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-7 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8341_"
            d="m 195,365.3 c -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.3,3.4 7.3,7.3 0,4 -3.4,7.2 -7.4,7.2 z"
          />
          <path
            id="XMLID_8340_"
            d="m 219.4,365.3 c -4.1,0.1 -7.3,-3 -7.4,-7.1 0,-4.1 3.2,-7.4 7.2,-7.4 3.8,0 7,3.1 7.2,6.9 0.2,4 -3,7.4 -7,7.6 z"
          />
          <path
            id="XMLID_8339_"
            d="m 250.4,358 c 0.1,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4.1,-0.2 7.5,3.1 7.6,7.1 z"
          />
          <path
            id="XMLID_8338_"
            d="m 274.4,358 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4,-0.2 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8337_"
            d="m 298.4,358.1 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_154_"
            d="m 298.4,334.1 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8336_"
            d="m 322.4,358 c 0,4 -3.3,7.3 -7.3,7.2 -3.9,0 -7,-3 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,0 7.4,3.4 7.4,7.3 z"
          />
          <path
            id="XMLID_152_"
            d="m 346.3,358 c 0,4 -3.3,7.3 -7.3,7.2 -3.9,0 -7,-3 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,0 7.3,3.4 7.4,7.3 z"
          />
          <path
            id="XMLID_8335_"
            d="m 410.6,365.2 c -4.1,-0.3 -6.9,-3.7 -6.5,-7.9 0.3,-3.8 3.7,-6.8 7.5,-6.5 4,0.3 7.2,3.8 6.9,7.7 -0.4,4.1 -3.9,7 -7.9,6.7 z"
          />
          <path
            id="XMLID_176_"
            d="m 410.6,341.2 c -4.1,-0.3 -6.9,-3.7 -6.5,-7.9 0.3,-3.8 3.7,-6.8 7.5,-6.5 4,0.3 7.2,3.8 6.9,7.7 -0.4,4.1 -3.9,7 -7.9,6.7 z"
          />
          <path
            id="XMLID_175_"
            d="m 422.4,377.3 c -4.1,-0.3 -6.9,-3.7 -6.5,-7.9 0.3,-3.8 3.7,-6.8 7.5,-6.5 4,0.3 7.2,3.8 6.9,7.7 -0.3,4 -3.8,7 -7.9,6.7 z"
          />
          <path
            id="XMLID_8334_"
            d="m 514.4,358.1 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8309_"
            d="m 82.4,382 c 0.1,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4.1,-0.2 7.5,3.1 7.6,7.1 z"
          />
          <path
            id="XMLID_8308_"
            d="m 106.4,381.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8307_"
            d="m 146.9,374.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8306_"
            d="m 178.4,382.1 c 0,4 -3.3,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-7 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8305_"
            d="m 202.4,381.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7.1,-2.8 -7.3,-6.7 -0.2,-4.1 2.8,-7.5 6.8,-7.7 4.1,-0.3 7.6,3 7.7,7 z"
          />
          <path
            id="XMLID_8304_"
            d="m 219,389.3 c -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.3,3.4 7.3,7.3 0,4 -3.4,7.2 -7.4,7.2 z"
          />
          <path
            id="XMLID_8303_"
            d="m 250.4,382.1 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8302_"
            d="m 274.4,382.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.4 -6.9,-7.5 0.2,-3.9 3.4,-6.9 7.2,-6.9 3.9,0 7.3,3.4 7.2,7.3 z"
          />
          <path
            id="XMLID_8301_"
            d="m 290.6,389.2 c -4.1,-0.3 -6.9,-3.7 -6.5,-7.9 0.3,-3.8 3.7,-6.8 7.5,-6.5 4,0.3 7.2,3.8 6.9,7.7 -0.4,4.1 -3.9,7.1 -7.9,6.7 z"
          />
          <path
            id="XMLID_150_"
            d="m 314.4,389.2 c -4.1,-0.3 -6.9,-3.7 -6.5,-7.9 0.3,-3.8 3.7,-6.8 7.5,-6.5 4,0.3 7.2,3.8 6.9,7.7 -0.3,4.1 -3.8,7.1 -7.9,6.7 z"
          />
          <path
            id="XMLID_8257_"
            d="m 202.4,405.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7.1,-2.8 -7.3,-6.7 -0.2,-4.1 2.8,-7.5 6.8,-7.7 4.1,-0.3 7.6,3 7.7,7 z"
          />
          <path
            id="XMLID_8256_"
            d="m 226.4,406.2 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.1 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.9 4,0.2 7.3,3.6 7.2,7.5 z"
          />
          <path
            id="XMLID_8255_"
            d="m 243,413.3 c -4.1,-0.1 -7.2,-3.3 -7,-7.4 0.1,-3.9 3.2,-7 7.1,-7 4,0 7.4,3.3 7.3,7.3 0,3.9 -3.3,7.1 -7.4,7.1 z"
          />
          <path
            id="XMLID_8254_"
            d="m 274.4,406.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8253_"
            d="m 298.4,406.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.4 -6.9,-7.5 0.2,-3.9 3.4,-6.9 7.2,-6.9 3.9,0 7.3,3.4 7.2,7.3 z"
          />
          <path
            id="XMLID_8252_"
            d="m 435.2,422.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.1 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_182_"
            d="m 435.2,398.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.1 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8251_"
            d="m 410.6,437.2 c -4.1,-0.3 -6.9,-3.7 -6.5,-7.9 0.3,-3.8 3.7,-6.8 7.5,-6.5 4,0.3 7.2,3.8 6.9,7.7 -0.4,4.1 -3.9,7.1 -7.9,6.7 z"
          />
          <path
            id="XMLID_183_"
            d="m 386.5,437.2 c -4.1,-0.3 -6.9,-3.7 -6.5,-7.9 0.3,-3.8 3.7,-6.8 7.5,-6.5 4,0.3 7.2,3.8 6.9,7.7 -0.3,4.1 -3.8,7.1 -7.9,6.7 z"
          />
          <path
            id="XMLID_179_"
            d="m 410.6,413.3 c -4.1,-0.3 -6.9,-3.7 -6.5,-7.9 0.3,-3.8 3.7,-6.8 7.5,-6.5 4,0.3 7.2,3.8 6.9,7.7 -0.4,4 -3.9,7 -7.9,6.7 z"
          />
          <path
            id="XMLID_8231_"
            d="m 346.4,430.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.4 -6.9,-7.5 0.2,-3.9 3.4,-6.9 7.2,-6.9 3.9,0 7.3,3.4 7.2,7.3 z"
          />
          <path
            id="XMLID_8230_"
            d="m 315,437.3 c -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.3,3.4 7.3,7.3 0,4 -3.4,7.2 -7.4,7.2 z"
          />
          <path
            id="XMLID_8229_"
            d="m 298.4,430.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.4 -6.9,-7.5 0.2,-3.9 3.4,-6.9 7.2,-6.9 3.9,0 7.3,3.4 7.2,7.3 z"
          />
          <path
            id="XMLID_8228_"
            d="m 274.4,430 c 0,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.1,-6.9 -0.1,-4.1 2.9,-7.5 7,-7.6 3.9,-0.1 7.4,3.2 7.4,7.2 z"
          />
          <path
            id="XMLID_8227_"
            d="m 242.9,422.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8226_"
            d="m 218.9,422.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8219_"
            d="m 562.4,94.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8217_"
            d="m 519.2,98.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -6.9,7 -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-3.7 3.5,-7.3 7.3,-7.3 z"
          />
          <path
            id="XMLID_8216_"
            d="m 387.1,125.3 c -4.1,-0.1 -7.2,-3.3 -7.1,-7.4 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 z"
          />
          <path
            id="XMLID_257_"
            d="m 363.1,125.3 c -4.1,-0.1 -7.2,-3.3 -7.1,-7.4 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 z"
          />
          <path
            id="XMLID_258_"
            d="m 339,125.3 c -4.1,-0.1 -7.2,-3.3 -7.1,-7.4 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0.1,4 -3.2,7.2 -7.3,7.2 z"
          />
          <path
            id="XMLID_252_"
            d="m 387.1,149.2 c -4.1,-0.1 -7.2,-3.3 -7.1,-7.4 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 z"
          />
          <path
            id="XMLID_260_"
            d="m 410.8,149.2 c -4.1,-0.1 -7.2,-3.3 -7.1,-7.4 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 z"
          />
          <path
            id="XMLID_253_"
            d="m 387.1,173.4 c -4.1,-0.1 -7.2,-3.3 -7.1,-7.4 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 z"
          />
          <path
            id="XMLID_254_"
            d="m 398.9,161.3 c -4.1,-0.1 -7.2,-3.3 -7.1,-7.4 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0.1,4 -3.2,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_255_"
            d="m 374.9,161.3 c -4.1,-0.1 -7.2,-3.3 -7.1,-7.4 0.1,-3.9 3.2,-7 7,-7 4,-0.1 7.4,3.3 7.4,7.2 0.1,4 -3.2,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_8215_"
            d="m 418.4,118.5 c -0.3,4 -3.8,7 -7.8,6.7 -4.1,-0.3 -6.9,-3.7 -6.6,-7.8 0.3,-3.8 3.7,-6.8 7.4,-6.6 4.1,0.3 7.3,3.8 7,7.7 z"
          />
          <path
            id="XMLID_259_"
            d="m 418.4,94.5 c -0.3,4 -3.8,7 -7.8,6.7 -4.1,-0.3 -6.9,-3.7 -6.6,-7.8 0.3,-3.8 3.7,-6.8 7.4,-6.6 4.1,0.2 7.3,3.8 7,7.7 z"
          />
          <path
            id="XMLID_8214_"
            d="m 459,125.3 c -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.3,3.4 7.3,7.3 0,4 -3.4,7.2 -7.4,7.2 z"
          />
          <path
            id="XMLID_8213_"
            d="m 538.4,118 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4,-0.2 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_139_"
            d="m 538.4,93.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8209_"
            d="m 466.4,142 c 0.1,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4.1,-0.2 7.5,3.1 7.6,7.1 z"
          />
          <path
            id="XMLID_131_"
            d="m 442.4,142 c 0.1,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4.1,-0.2 7.5,3.1 7.6,7.1 z"
          />
          <path
            id="XMLID_8208_"
            d="m 490.4,142.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.4 -6.9,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8207_"
            d="m 507.2,134.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.2 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_140_"
            d="m 507.2,110.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.2 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8206_"
            d="m 531.4,134.8 c 4,0.2 7.2,3.7 7,7.6 -0.2,4 -3.6,7.1 -7.7,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.3,-3.9 3.6,-6.9 7.4,-6.8 z"
          />
          <path
            id="XMLID_8205_"
            d="m 562.4,141.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_181_"
            d="m 615.5,141.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8186_"
            d="m 370.4,525.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_149_"
            d="m 370.4,550 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.2 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8185_"
            d="m 346.4,526.1 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8184_"
            d="m 315.2,518.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.8,-3.2 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8181_"
            d="m 459.2,158.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.2 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8180_"
            d="m 490.4,166.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8179_"
            d="m 514.4,166 c 0,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,-0.1 7.4,3.2 7.4,7.2 z"
          />
          <path
            id="XMLID_8178_"
            d="m 531.2,158.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.2 -6.8,-3.2 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8177_"
            d="m 555.1,158.8 c 4,0 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.2,-4.1 2.9,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8176_"
            d="m 579.3,173.3 c -4.1,0.1 -7.3,-3 -7.3,-7.1 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,3.9 -3,7.3 -7.1,7.4 z"
          />
          <path
            id="XMLID_8174_"
            d="m 363.1,182.8 c 4,-0.1 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-6.9 -0.2,-4.2 2.9,-7.5 7,-7.6 z"
          />
          <path
            id="XMLID_250_"
            d="m 327.2,146.9 c 4,-0.1 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-6.9 -0.2,-4.2 2.9,-7.6 7,-7.6 z"
          />
          <path
            id="XMLID_246_"
            d="m 363.1,206.8 c 4,-0.1 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-6.9 -0.2,-4.2 2.9,-7.5 7,-7.6 z"
          />
          <path
            id="XMLID_247_"
            d="m 363.1,230.8 c 4,-0.1 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-6.9 -0.2,-4.2 2.9,-7.5 7,-7.6 z"
          />
          <path
            id="XMLID_8173_"
            d="m 418.4,190.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.4 -6.9,-7.5 0.2,-3.9 3.4,-6.9 7.2,-6.9 3.9,0 7.3,3.4 7.2,7.3 z"
          />
          <path
            id="XMLID_8172_"
            d="m 435.2,197.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0,-3.9 3,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.4 z"
          />
          <path
            id="XMLID_8154_"
            d="m 466.4,190.2 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.1 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.9 4,0.2 7.3,3.6 7.2,7.5 z"
          />
          <path
            id="XMLID_8153_"
            d="m 483.2,182.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.8,-3.2 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8152_"
            d="m 507.4,182.8 c 4,0.2 7.2,3.7 7,7.6 -0.2,4 -3.6,7.1 -7.7,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.3,-3.9 3.6,-6.9 7.4,-6.8 z"
          />
          <path
            id="XMLID_8151_"
            d="m 538.4,189.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8148_"
            d="m 442.4,213.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8147_"
            d="m 466.4,214.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8146_"
            d="m 483.2,221.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.4 -7.2,7.4 z"
          />
          <path
            id="XMLID_8145_"
            d="m 514.4,214 c 0,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,-0.1 7.4,3.2 7.4,7.2 z"
          />
          <path
            id="XMLID_8144_"
            d="m 531.3,221.3 c -4.1,0.1 -7.3,-3 -7.3,-7.2 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,4 -3.1,7.4 -7.1,7.5 z"
          />
          <path
            id="XMLID_8143_"
            d="m 562.4,213.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_137_"
            d="m 586.4,213.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8111_"
            d="m 394.4,502.1 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8110_"
            d="m 356,502.1 c 0,-4.1 3.2,-7.4 7.2,-7.3 4,0.1 7.3,3.5 7.2,7.4 -0.1,3.8 -3.2,6.9 -7.1,7 -4.1,0.2 -7.3,-2.9 -7.3,-7.1 z"
          />
          <path
            id="XMLID_8109_"
            d="m 346.4,501.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8108_"
            d="m 322.4,502.1 c 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-6.9 -0.1,-4.1 3,-7.4 7.1,-7.5 3.9,-0.1 7.3,3.2 7.3,7.2 z"
          />
          <path
            id="XMLID_8107_"
            d="m 291.2,509.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.4 -7.2,7.4 z"
          />
          <path
            id="XMLID_8106_"
            d="m 267.3,509.3 c -4.1,0.1 -7.3,-3 -7.3,-7.1 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,3.9 -3,7.3 -7.1,7.4 z"
          />
          <path
            id="XMLID_8098_"
            d="m 243.1,494.8 c 4,0 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.2,-4.1 2.9,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8097_"
            d="m 266.9,230.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_162_"
            d="m 242.9,230.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8096_"
            d="m 219.3,494.8 c 4,0.1 7.3,3.6 7.1,7.5 -0.2,4 -3.6,7.1 -7.6,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.2,-3.8 3.5,-6.8 7.2,-6.7 z"
          />
          <path
            id="XMLID_8095_"
            d="m 490.4,238.2 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.1 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.9 4,0.2 7.3,3.6 7.2,7.5 z"
          />
          <path
            id="XMLID_134_"
            d="m 466.4,238.2 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.1 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.9 4,0.2 7.3,3.6 7.2,7.5 z"
          />
          <path
            id="XMLID_8094_"
            d="m 514.4,238.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8093_"
            d="m 531.2,245.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0,-3.9 3,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.4 z"
          />
          <path
            id="XMLID_8092_"
            d="m 555,245.3 c -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.3,3.4 7.3,7.3 0,4 -3.4,7.2 -7.4,7.2 z"
          />
          <path
            id="XMLID_136_"
            d="m 579.2,245.3 c -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.3,3.4 7.3,7.3 0,4 -3.3,7.2 -7.4,7.2 z"
          />
          <path
            id="XMLID_8080_"
            d="m 483.3,269.3 c -4.1,0.1 -7.3,-3 -7.3,-7.2 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,4 -3.1,7.4 -7.1,7.5 z"
          />
          <path
            id="XMLID_8079_"
            d="m 506.6,269.2 c -4.1,-0.3 -6.9,-3.7 -6.5,-7.9 0.3,-3.8 3.7,-6.8 7.5,-6.5 4,0.3 7.2,3.8 6.9,7.7 -0.4,4.1 -3.9,7.1 -7.9,6.7 z"
          />
          <path
            id="XMLID_8078_"
            d="m 538.4,261.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8077_"
            d="m 555.4,269.3 c -4.1,0.1 -7.3,-3 -7.4,-7.1 0,-4.1 3.2,-7.4 7.2,-7.4 3.8,0 7,3.1 7.2,6.9 0.2,4 -3,7.4 -7,7.6 z"
          />
          <path
            id="XMLID_8055_"
            d="m 555.1,182.8 c 4,0 7.4,3.3 7.3,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.1,-4.1 3,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_138_"
            d="m 578.8,182.8 c 4,0 7.4,3.3 7.3,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.1,-4.1 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8026_"
            d="m 514.4,286 c 0,4 -3.3,7.3 -7.3,7.2 -3.9,0 -7,-3 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,0 7.4,3.4 7.4,7.3 z"
          />
          <path
            id="XMLID_8025_"
            d="m 531.2,293.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.4 -7.2,7.4 z"
          />
          <path
            id="XMLID_8024_"
            d="m 548,286.1 c 0,-4.1 3.2,-7.4 7.2,-7.3 4,0.1 7.3,3.5 7.2,7.4 -0.1,3.8 -3.2,6.9 -7.1,7 -4.1,0.2 -7.3,-2.9 -7.3,-7.1 z"
          />
          <path
            id="XMLID_8004_"
            d="m 411.2,485.3 c -4.1,0 -7.2,-3.2 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.1 3.9,-0.1 7.4,3.2 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_8003_"
            d="m 387.3,485.3 c -4.1,0.1 -7.3,-3 -7.3,-7.2 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,4 -3.1,7.4 -7.1,7.5 z"
          />
          <path
            id="XMLID_8002_"
            d="m 370.4,478 c 0,4 -3.3,7.3 -7.3,7.2 -3.9,0 -7,-3 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,0 7.4,3.4 7.4,7.3 z"
          />
          <path
            id="XMLID_8001_"
            d="m 338.6,485.2 c -4.1,-0.3 -6.9,-3.7 -6.5,-7.9 0.3,-3.8 3.7,-6.8 7.5,-6.5 4,0.3 7.2,3.8 6.9,7.7 -0.4,4.1 -3.9,7.1 -7.9,6.7 z"
          />
          <path
            id="XMLID_8000_"
            d="m 315,485.3 c -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.3,3.4 7.3,7.3 0,4 -3.4,7.2 -7.4,7.2 z"
          />
          <path
            id="XMLID_7999_"
            d="m 298.4,478 c 0,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,-0.1 7.4,3.2 7.4,7.2 z"
          />
          <path
            id="XMLID_7998_"
            d="m 267.2,485.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0,-3.9 3,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.4 z"
          />
          <path
            id="XMLID_7997_"
            d="m 250.4,477.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_235_"
            d="m 226.4,477.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_7996_"
            d="m 195.1,302.8 c 4,-0.1 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-6.9 -0.2,-4.2 2.9,-7.5 7,-7.6 z"
          />
          <path
            id="XMLID_7989_"
            d="m 274.4,310.2 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.1 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.9 4,0.2 7.3,3.6 7.2,7.5 z"
          />
          <path
            id="XMLID_7988_"
            d="m 514.4,309.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_7987_"
            d="m 538.4,310.5 c -0.3,4 -3.8,7 -7.8,6.7 -4.1,-0.3 -6.9,-3.6 -6.6,-7.8 0.3,-3.8 3.7,-6.8 7.4,-6.6 4.1,0.3 7.3,3.8 7,7.7 z"
          />
          <path
            id="XMLID_7982_"
            d="m 555.2,302.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.8,-3.2 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_7971_"
            d="m 219.2,293.3 c -3.8,0.1 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 7,-7.6 3.6,-0.1 7.1,2.9 7.3,6.3 0.2,3.9 -3.3,7.9 -7.1,8.1 z"
          />
          <path
            id="XMLID_165_"
            d="m 219.2,269.3 c -3.8,0.1 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 7,-7.6 3.6,-0.1 7.1,2.9 7.3,6.3 0.2,3.9 -3.3,8 -7.1,8.1 z"
          />
        </g>
        <g
          id="Central"
          fill={region === "central-america" ? "#244588" : undefined}
        >
          <path
            id="XMLID_8810_"
            d="m 279.1,545.3 c -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.5,-7.1 7.6,-7 3.9,0.2 6.8,3.2 6.8,7.2 0,4.1 -3.2,7.3 -7.2,7.2 z"
          />
          <path
            id="XMLID_8808_"
            d="m 255.3,530.9 c 4.1,0.1 7.1,3.3 7,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.9 3.4,-7.2 7.4,-7.1 z"
          />
          <path
            id="XMLID_8712_"
            d="m 238.3,513.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_8525_"
            d="m 279,554.9 c 4.1,0 7.3,3.1 7.2,7.2 0,3.9 -2.9,7 -6.8,7.2 -4,0.2 -7.5,-3 -7.6,-7 -0.1,-3.9 3.2,-7.4 7.2,-7.4 z"
          />
          <path
            id="XMLID_8524_"
            d="m 310.3,562.3 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.2 -6.9,-3.3 -7,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 4.1,-0.1 7.2,3.1 7.1,7.3 z"
          />
          <path
            id="XMLID_236_"
            d="m 310.3,586.3 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.2 -6.9,-3.3 -7,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 4.1,-0.1 7.2,3.1 7.1,7.3 z"
          />
          <path
            id="XMLID_8221_"
            d="m 322.4,550.2 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.1 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.9 4,0.2 7.3,3.6 7.2,7.5 z"
          />
          <path
            id="XMLID_145_"
            d="m 322.4,598.7 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.1 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.9 4,0.1 7.3,3.5 7.2,7.5 z"
          />
          <path
            id="XMLID_146_"
            d="m 346.4,598.7 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.1 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.9 3.9,0.1 7.3,3.5 7.2,7.5 z"
          />
          <path
            id="XMLID_147_"
            d="m 358.3,610.3 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.1 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.9 3.9,0.2 7.3,3.6 7.2,7.5 z"
          />
          <path
            id="XMLID_8220_"
            d="m 291.1,557.3 c -4.1,0 -7.2,-3.2 -7.1,-7.4 0.1,-3.9 3.1,-7 7,-7.1 4,-0.1 7.4,3.2 7.4,7.2 0,4 -3.2,7.3 -7.3,7.3 z"
          />
          <path
            id="XMLID_8218_"
            d="m 267.4,542.8 c 4,0.2 7.2,3.7 7,7.6 -0.2,4 -3.6,7.1 -7.7,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.3,-3.9 3.6,-6.9 7.4,-6.8 z"
          />
          <path
            id="XMLID_8183_"
            d="m 291,518.8 c 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 -3.9,0 -6.9,-3 -7.1,-6.9 -0.1,-4.1 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8182_"
            d="m 274.4,526.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8175_"
            d="m 243.1,518.8 c 4,-0.1 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-6.9 -0.2,-4.2 2.9,-7.5 7,-7.6 z"
          />
          <path
            id="XMLID_8171_"
            d="m 298.4,574.2 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.1 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.9 4,0.2 7.3,3.6 7.2,7.5 z"
          />
          <path
            id="XMLID_148_"
            d="m 411.2,591.3 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.1 -6.9,-7.1 0,-4.1 3.2,-7.4 7.2,-7.3 z"
          />
        </g>
        <g
          id="australia"
          fill={region === "australia-oceania" ? "#244588" : undefined}
        >
          <path
            id="XMLID_312_"
            d="m 1174.3,682.7 c 0.1,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.2,0 7.4,3 7.4,7.1 z"
          />
          <path
            id="XMLID_8445_"
            d="m 1143.2,737.3 c -4,0 -7.3,-3.3 -7.3,-7.3 0.1,-4 3.4,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7 0,4.2 -3.1,7.4 -7.1,7.4 z"
          />
          <path
            id="XMLID_319_"
            d="m 1119.2,737.3 c -4,0 -7.3,-3.3 -7.3,-7.3 0.1,-4 3.4,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7 0,4.2 -3.1,7.4 -7.1,7.4 z"
          />
          <path
            id="XMLID_318_"
            d="m 1191.2,737.3 c -4,0 -7.3,-3.3 -7.3,-7.3 0.1,-4 3.4,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7 0,4.2 -3.1,7.4 -7.1,7.4 z"
          />
          <path
            id="XMLID_8437_"
            d="m 1102.3,754.3 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.2 -6.9,-3.3 -7,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 4.1,-0.1 7.2,3.1 7.1,7.3 z"
          />
          <path
            id="XMLID_8436_"
            d="m 1126.3,754.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8435_"
            d="m 1150.3,754 c 0.1,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_8434_"
            d="m 1174.3,754 c 0.1,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_8433_"
            d="m 1198.3,753.8 c 0.1,4.2 -2.9,7.4 -7,7.5 -4,0 -7.4,-3.2 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.1,-0.2 7.4,2.8 7.5,6.9 z"
          />
          <path
            id="XMLID_315_"
            d="m 1198.3,682.6 c 0.1,4.2 -2.9,7.4 -7,7.5 -4,0 -7.4,-3.2 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.1,-0.2 7.4,2.8 7.5,6.9 z"
          />
          <path
            id="XMLID_8427_"
            d="m 1095,770.9 c 4.1,-0.1 7.3,3 7.3,7.2 0,4 -2.9,7.1 -6.7,7.2 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8426_"
            d="m 1126.3,777.9 c 0.1,4.2 -3,7.4 -7.1,7.4 -4,0 -7.4,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_8425_"
            d="m 1150.3,777.9 c 0.1,4.2 -3,7.4 -7,7.4 -4,0 -7.4,-3.3 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.2,-0.2 7.4,2.9 7.5,7 z"
          />
          <path
            id="XMLID_8424_"
            d="m 1174.3,778.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_8423_"
            d="m 1191.1,770.9 c 4.1,0 7.2,3.1 7.2,7.3 -0.1,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-3.9 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8422_"
            d="m 1215.2,785.3 c -4,0 -7.4,-3.3 -7.4,-7.2 0,-4 3.4,-7.2 7.4,-7.2 3.9,0.1 6.9,3 7,7 0.2,4.1 -2.9,7.4 -7,7.4 z"
          />
          <path
            id="XMLID_8419_"
            d="m 1198.3,802.3 c -0.1,4.1 -3.4,7.2 -7.4,7 -3.8,-0.2 -6.9,-3.4 -7,-7.1 0,-4 3.3,-7.3 7.3,-7.3 4.1,0 7.2,3.2 7.1,7.4 z"
          />
          <path
            id="XMLID_323_"
            d="m 1294,850.2 c -0.1,4.1 -3.4,7.2 -7.4,7 -3.8,-0.2 -6.9,-3.4 -7,-7.1 0,-4 3.3,-7.3 7.3,-7.3 4.2,0 7.2,3.2 7.1,7.4 z"
          />
          <path
            id="XMLID_311_"
            d="m 1131.2,703.5 c -4.1,0 -7.2,-3.2 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.1 3.9,-0.1 7.4,3.2 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_313_"
            d="m 1179.2,703.5 c -4.1,0 -7.2,-3.2 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.1 3.9,-0.1 7.4,3.2 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_314_"
            d="m 1155.2,677.3 c -4.1,0 -7.2,-3.2 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.1 3.9,-0.1 7.4,3.2 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_8030_"
            d="m 1138.4,741.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_320_"
            d="m 1114.4,741.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8029_"
            d="m 1162.4,742.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_316_"
            d="m 1162.4,718.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8028_"
            d="m 1179.2,749.3 c -4.1,0 -7.2,-3.2 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.1 3.9,-0.1 7.4,3.2 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_8011_"
            d="m 1107.2,773.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0,-3.9 3,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.4 z"
          />
          <path
            id="XMLID_322_"
            d="m 1107.2,797.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0,-3.9 3,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.4 -7.2,7.4 z"
          />
          <path
            id="XMLID_321_"
            d="m 1083.2,773.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0,-3.9 3,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.4 z"
          />
          <path
            id="XMLID_8010_"
            d="m 1131.4,773.3 c -4.1,0.1 -7.3,-3 -7.4,-7.1 0,-4.1 3.2,-7.4 7.2,-7.4 3.8,0 7,3.1 7.2,6.9 0.2,4 -3,7.4 -7,7.6 z"
          />
          <path
            id="XMLID_8009_"
            d="m 1162.4,765.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_317_"
            d="m 1162.4,789.6 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.2 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8008_"
            d="m 1179,758.8 c 4,-0.1 7.4,3.3 7.4,7.2 0,4 -3.3,7.2 -7.3,7.2 -3.9,0 -6.9,-3 -7.1,-6.9 -0.1,-4.1 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8007_"
            d="m 1203.3,758.8 c 4,0.1 7.3,3.6 7.1,7.5 -0.2,4 -3.6,7.1 -7.6,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.2,-3.8 3.5,-6.8 7.2,-6.7 z"
          />
          <path
            id="XMLID_7991_"
            d="m 1179.3,797.3 c -4.1,0.1 -7.3,-3 -7.3,-7.1 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,3.9 -3,7.3 -7.1,7.4 z"
          />
          <path
            id="XMLID_7990_"
            d="m 1203.2,797.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.4 -7.2,7.4 z"
          />
          <path
            id="XMLID_324_"
            d="m 1311.1,832.8 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.4 z"
          />
          <path
            id="XMLID_325_"
            d="m 1299.1,845.1 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4.1 -3.2,7.4 -7.2,7.4 z"
          />
          <path
            id="XMLID_326_"
            d="m 1299.1,818.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4.1 -3.2,7.4 -7.2,7.4 z"
          />
          <path
            id="XMLID_7928_"
            d="m 1279.9,850.3 c -0.1,-3.2 2.2,-6.3 5.3,-7.1 3.2,-0.9 6.7,0.5 8.2,3.3 0.4,0.8 0.6,1.8 0.4,2.7 -0.7,3.5 -3,5.9 -5.9,7.7 -0.6,0.4 -1.5,0.4 -2.3,0.3 -3.2,-0.7 -5.6,-3.7 -5.7,-6.9 z"
          />
          <path
            id="XMLID_7356_"
            d="m 1138.3,718 c 0,0.2 0,0.3 0,0.5 -0.1,-0.2 -0.2,-0.3 -0.3,-0.5 0,-0.1 0.1,-0.3 0.1,-0.4 0.1,0.1 0.1,0.3 0.2,0.4 z"
          />
        </g>
        <g id="africa" fill={region === "africa" ? "#244588" : undefined}>
          <path
            id="XMLID_8821_"
            d="m 687.3,545.3 c -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.2,4.3 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_194_"
            d="m 663.3,545.3 c -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.2,4.3 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8523_"
            d="m 639.4,569.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 3.9,0 7,2.9 7.2,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8522_"
            d="m 663,569.3 c -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-4 3.6,-7.1 7.6,-6.9 3.9,0.2 6.8,3.2 6.8,7.2 0,4.1 -3.2,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_8521_"
            d="m 687.3,569.3 c -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.2,4.3 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8520_"
            d="m 718.3,562 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.2,-7 7,-7.1 4.2,0 7.4,3.1 7.4,7.2 z"
          />
          <path
            id="XMLID_8519_"
            d="m 742.3,562.3 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.2 -6.9,-3.3 -7,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 4.1,-0.1 7.2,3.1 7.1,7.3 z"
          />
          <path
            id="XMLID_8518_"
            d="m 759,569.3 c -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-4 3.6,-7.1 7.7,-6.9 3.9,0.2 6.8,3.3 6.7,7.2 0,4.2 -3.3,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_198_"
            d="m 783,569.3 c -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-4 3.6,-7.1 7.7,-6.9 3.9,0.2 6.8,3.3 6.7,7.2 0,4.2 -3.3,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_8507_"
            d="m 615.4,578.9 c 4.1,0.1 7.1,3.3 6.9,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.3,-3.3 -7.3,-7.3 0,-4 3.4,-7.2 7.5,-7.1 z"
          />
          <path
            id="XMLID_8506_"
            d="m 646.3,586.3 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.2 -6.9,-3.3 -7,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 4.1,-0.1 7.2,3.1 7.1,7.3 z"
          />
          <path
            id="XMLID_8505_"
            d="m 670.3,586.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8504_"
            d="m 687.4,593.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 3.9,0 7,2.9 7.2,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8503_"
            d="m 711,578.9 c 4.1,-0.1 7.3,3 7.3,7.1 0,4 -2.9,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8502_"
            d="m 735.1,593.3 c -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.5,-7.1 7.6,-7 3.9,0.2 6.8,3.2 6.8,7.2 0,4.1 -3.2,7.3 -7.2,7.2 z"
          />
          <path
            id="XMLID_8501_"
            d="m 766.3,586 c 0.1,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_8500_"
            d="m 783,578.9 c 4.1,-0.1 7.3,3 7.3,7.1 0,4 -2.9,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8495_"
            d="m 622.3,609.9 c 0.1,4.2 -3,7.4 -7,7.4 -4,0 -7.4,-3.2 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.1,-0.2 7.4,2.8 7.5,7 z"
          />
          <path
            id="XMLID_8494_"
            d="m 646.3,610.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8493_"
            d="m 663.2,602.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -7,7 -4,0.1 -7.4,-3.2 -7.4,-7.2 -0.1,-3.9 3.3,-7.2 7.3,-7.2 z"
          />
          <path
            id="XMLID_8492_"
            d="m 694.3,610.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8491_"
            d="m 711,617.3 c -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-4 3.6,-7.1 7.6,-6.9 3.9,0.2 6.8,3.2 6.8,7.2 0,4.1 -3.2,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_8490_"
            d="m 742.3,610 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.2,-7 7,-7.1 4.2,0 7.4,3.1 7.4,7.2 z"
          />
          <path
            id="XMLID_8489_"
            d="m 766.3,609.8 c 0.1,4.2 -2.9,7.4 -7,7.5 -4,0 -7.4,-3.2 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.1,-0.2 7.4,2.8 7.5,6.9 z"
          />
          <path
            id="XMLID_8488_"
            d="m 783.2,602.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -6.9,7 -4,0.1 -7.4,-3.2 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 z"
          />
          <path
            id="XMLID_8484_"
            d="m 639.2,641.3 c -4,0 -7.3,-3.3 -7.3,-7.3 0.1,-4 3.4,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7 0,4.2 -3.1,7.4 -7.1,7.4 z"
          />
          <path
            id="XMLID_8483_"
            d="m 663.3,626.9 c 4.1,0.1 7.1,3.3 6.9,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.4,-3.3 -7.3,-7.3 0.1,-4 3.5,-7.2 7.5,-7.1 z"
          />
          <path
            id="XMLID_8482_"
            d="m 694.3,634.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8481_"
            d="m 718.3,634.3 c -0.1,4.1 -3.4,7.2 -7.5,7 -3.8,-0.2 -6.9,-3.4 -6.9,-7.2 0,-4 3.3,-7.3 7.4,-7.2 4.1,0.1 7.1,3.2 7,7.4 z"
          />
          <path
            id="XMLID_8480_"
            d="m 735.8,626.9 c 4,0.3 6.8,3.7 6.5,7.9 -0.3,3.8 -3.6,6.7 -7.5,6.5 -4,-0.2 -7.2,-3.8 -6.9,-7.7 0.3,-4 3.8,-7 7.9,-6.7 z"
          />
          <path
            id="XMLID_8479_"
            d="m 766.3,634.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8478_"
            d="m 783.3,626.9 c 4.1,0.1 7.1,3.3 6.9,7.5 -0.1,3.9 -3.2,6.9 -7.1,6.9 -4,0 -7.4,-3.3 -7.3,-7.3 0.1,-4 3.5,-7.2 7.5,-7.1 z"
          />
          <path
            id="XMLID_8477_"
            d="m 807.3,626.9 c 4.1,0.1 7.1,3.2 7,7.4 -0.1,3.9 -3.1,6.9 -7,7 -4,0 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.4,-7.2 z"
          />
          <path
            id="XMLID_193_"
            d="m 831.4,626.9 c 4.1,0.1 7.1,3.2 7,7.4 -0.1,3.9 -3.1,6.9 -7,7 -4,0 -7.4,-3.2 -7.4,-7.2 0,-4 3.3,-7.3 7.4,-7.2 z"
          />
          <path
            id="XMLID_8473_"
            d="m 718.3,658.3 c -0.1,4.1 -3.4,7.2 -7.5,7 -3.8,-0.2 -6.9,-3.4 -6.9,-7.2 0,-4 3.3,-7.3 7.4,-7.2 4.1,0.1 7.1,3.2 7,7.4 z"
          />
          <path
            id="XMLID_8472_"
            d="m 742.3,658.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8471_"
            d="m 759,665.3 c -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-4 3.6,-7.1 7.6,-7 3.9,0.2 6.8,3.2 6.8,7.2 0.1,4.1 -3.1,7.3 -7.2,7.2 z"
          />
          <path
            id="XMLID_8470_"
            d="m 783.3,665.3 c -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.2,4.3 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8469_"
            d="m 807.1,665.3 c -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-4 3.5,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7.1 0,4.2 -3.1,7.4 -7.2,7.4 z"
          />
          <path
            id="XMLID_8463_"
            d="m 742.3,681.9 c 0.1,4.2 -3,7.4 -7,7.4 -4,0 -7.4,-3.3 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.2,-0.2 7.4,2.9 7.5,7 z"
          />
          <path
            id="XMLID_189_"
            d="m 718.3,681.9 c 0.1,4.2 -3,7.4 -7,7.4 -4,0 -7.4,-3.3 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.2,-0.2 7.4,2.9 7.5,7 z"
          />
          <path
            id="XMLID_8462_"
            d="m 759.4,689.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 3.9,0 7,2.9 7.2,6.8 0.1,4.2 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8461_"
            d="m 782.9,674.9 c 4.1,-0.1 7.3,3 7.4,7.1 0,4 -2.8,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-3.9 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8455_"
            d="m 735,698.9 c 4.1,-0.1 7.3,3 7.3,7.2 0,4 -2.9,7.1 -6.7,7.2 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8454_"
            d="m 759,713.3 c -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-4 3.6,-7.1 7.7,-6.9 3.9,0.2 6.8,3.3 6.7,7.2 0,4.2 -3.3,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_8453_"
            d="m 790.3,706.3 c -0.1,4.1 -3.4,7.2 -7.4,7 -3.8,-0.2 -6.9,-3.4 -7,-7.1 0,-4 3.3,-7.3 7.3,-7.3 4.1,0 7.2,3.2 7.1,7.4 z"
          />
          <path
            id="XMLID_190_"
            d="m 814.3,706.3 c -0.1,4.1 -3.4,7.2 -7.4,7 -3.8,-0.2 -6.9,-3.4 -7,-7.1 0,-4 3.3,-7.3 7.3,-7.3 4.1,0 7.2,3.2 7.1,7.4 z"
          />
          <path
            id="XMLID_8448_"
            d="m 742.3,729.9 c 0.1,4.2 -3,7.4 -7,7.4 -4,0 -7.4,-3.2 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.1,-0.2 7.4,2.8 7.5,7 z"
          />
          <path
            id="XMLID_8447_"
            d="m 766.3,730.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,-0.1 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8446_"
            d="m 783.4,737.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -6.9,7.5 z"
          />
          <path
            id="XMLID_191_"
            d="m 831.4,737.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -6.9,7.5 z"
          />
          <path
            id="XMLID_8441_"
            d="m 742.3,754 c 0,4.2 -3.1,7.3 -7.2,7.3 -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-3.8 3.2,-7 7,-7.1 4.2,0 7.4,3.1 7.4,7.2 z"
          />
          <path
            id="XMLID_8440_"
            d="m 766.3,754.2 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.6 -7.1,-7.5 0.2,-3.8 3.4,-6.9 7.2,-6.9 4.1,-0.1 7.2,3 7.2,7.2 z"
          />
          <path
            id="XMLID_8439_"
            d="m 790.1,753.8 c -0.1,4.8 -3,7.7 -7.3,7.5 -3.8,-0.2 -6.9,-3.4 -6.9,-7.2 0,-4 3.3,-7.2 7.3,-7.2 3.8,-0.1 7.5,3.5 6.9,6.9 z"
          />
          <path
            id="XMLID_8438_"
            d="m 838.3,754.2 c 0,4.1 -3.3,7.3 -7.4,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 4.2,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_8429_"
            d="m 742.3,778 c 0.1,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_8428_"
            d="m 766.3,777.8 c 0.1,4.2 -2.9,7.4 -7,7.5 -4,0 -7.4,-3.2 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.1,-0.2 7.4,2.8 7.5,6.9 z"
          />
          <path
            id="XMLID_8224_"
            d="m 706.4,549.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8223_"
            d="m 682.4,550.1 c 0,4 -3.3,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-7 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8222_"
            d="m 650.9,542.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8170_"
            d="m 634.4,574 c 0,4 -3.3,7.3 -7.3,7.2 -3.9,0 -7,-3 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,0 7.4,3.4 7.4,7.3 z"
          />
          <path
            id="XMLID_8169_"
            d="m 651.1,566.8 c 4,0 7.4,3.3 7.3,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.1,-4.1 3,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8168_"
            d="m 682.4,573.9 c 0.1,4 -3.2,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8167_"
            d="m 706.4,574.5 c -0.3,4 -3.8,7 -7.8,6.7 -4.1,-0.3 -6.9,-3.6 -6.6,-7.8 0.3,-3.8 3.7,-6.8 7.4,-6.6 4.1,0.3 7.3,3.8 7,7.7 z"
          />
          <path
            id="XMLID_8166_"
            d="m 722.9,566.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8165_"
            d="m 754.4,574 c 0.1,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4.1,-0.2 7.5,3.1 7.6,7.1 z"
          />
          <path
            id="XMLID_197_"
            d="m 754.4,550 c 0.1,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 4.1,-0.2 7.5,3.1 7.6,7.1 z"
          />
          <path
            id="XMLID_8164_"
            d="m 771.1,566.8 c 4,0 7.4,3.3 7.3,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.1,-4.1 3,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8142_"
            d="m 627.3,605.3 c -4.1,0.1 -7.3,-3 -7.3,-7.1 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,3.9 -3,7.3 -7.1,7.4 z"
          />
          <path
            id="XMLID_8141_"
            d="m 658.4,597.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8140_"
            d="m 675.1,590.8 c 4,0 7.4,3.3 7.3,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.1,-4.1 3,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8139_"
            d="m 699.3,605.3 c -4.1,0.1 -7.3,-3 -7.3,-7.2 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,4 -3.1,7.4 -7.1,7.5 z"
          />
          <path
            id="XMLID_8138_"
            d="m 723.2,590.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.2 -6.8,-3.2 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8137_"
            d="m 754.4,598.3 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.2 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.8 4,0 7.3,3.4 7.2,7.4 z"
          />
          <path
            id="XMLID_8136_"
            d="m 778.4,597.9 c 0.1,4 -3.2,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8124_"
            d="m 627.2,629.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0,-3.9 3,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.4 z"
          />
          <path
            id="XMLID_8123_"
            d="m 651.2,629.3 c -4.1,0 -7.2,-3.2 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.1 3.9,-0.1 7.4,3.2 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_188_"
            d="m 651.2,653.3 c -4.1,0 -7.2,-3.2 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.1 3.9,-0.1 7.4,3.2 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_8122_"
            d="m 682.4,621.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8121_"
            d="m 706.4,622.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.4 -6.9,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8120_"
            d="m 730.4,622.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4.1,-0.1 7.5,3.3 7.4,7.3 z"
          />
          <path
            id="XMLID_8119_"
            d="m 754.4,622.1 c 0,4 -3.3,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-7 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8118_"
            d="m 771.3,629.3 c -4.1,0.1 -7.3,-3 -7.3,-7.2 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,4 -3.1,7.4 -7.1,7.5 z"
          />
          <path
            id="XMLID_8117_"
            d="m 802.4,621.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8103_"
            d="m 730.4,646.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_195_"
            d="m 706.4,646.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8102_"
            d="m 747.3,653.3 c -4.1,0.1 -7.3,-3 -7.3,-7.1 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,3.9 -3,7.3 -7.1,7.4 z"
          />
          <path
            id="XMLID_8101_"
            d="m 778.4,645.9 c 0.1,4 -3.2,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8100_"
            d="m 795.3,638.8 c 4,0.1 7.3,3.6 7.1,7.5 -0.2,4 -3.6,7.1 -7.6,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.2,-3.8 3.5,-6.8 7.2,-6.7 z"
          />
          <path
            id="XMLID_8099_"
            d="m 819.2,653.3 c -4.1,0 -7.2,-3.2 -7.2,-7.3 0.1,-3.9 3.1,-7 6.9,-7.1 3.9,-0.1 7.4,3.2 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.3 z"
          />
          <path
            id="XMLID_8086_"
            d="m 730.4,670.3 c -0.1,4 -3.5,7.1 -7.6,7 -4.1,-0.2 -7,-3.5 -6.8,-7.6 0.2,-3.8 3.5,-6.9 7.3,-6.8 3.9,0 7.2,3.5 7.1,7.4 z"
          />
          <path
            id="XMLID_8085_"
            d="m 740,669.7 c 0.2,-3.9 3.3,-6.9 7.2,-6.9 4,0 7.3,3.4 7.3,7.4 -0.1,4 -3.4,7.2 -7.5,7.1 -4.2,-0.2 -7.2,-3.4 -7,-7.6 z"
          />
          <path
            id="XMLID_8084_"
            d="m 778.4,670.1 c 0,4 -3.3,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-7 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8083_"
            d="m 795.4,662.8 c 4,0.2 7.2,3.7 7,7.6 -0.2,4 -3.6,7.1 -7.7,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.3,-3.9 3.6,-6.9 7.4,-6.8 z"
          />
          <path
            id="XMLID_8066_"
            d="m 723.3,701.3 c -4.1,0.1 -7.3,-3 -7.3,-7.2 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,4 -3.1,7.4 -7.1,7.5 z"
          />
          <path
            id="XMLID_8065_"
            d="m 754.4,694.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8064_"
            d="m 771,701.3 c -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0 7.3,3.4 7.3,7.3 0,4 -3.4,7.2 -7.4,7.2 z"
          />
          <path
            id="XMLID_8063_"
            d="m 802.4,694.2 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.1 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.9 4,0.2 7.3,3.6 7.2,7.5 z"
          />
          <path
            id="XMLID_8048_"
            d="m 716,718.1 c 0,-4.1 3.2,-7.4 7.2,-7.3 4,0.1 7.3,3.5 7.2,7.4 -0.1,3.8 -3.2,6.9 -7.1,7 -4.1,0.2 -7.3,-2.9 -7.3,-7.1 z"
          />
          <path
            id="XMLID_8047_"
            d="m 747.4,725.3 c -4.1,0.1 -7.3,-3 -7.4,-7.1 0,-4.1 3.2,-7.4 7.2,-7.4 3.8,0 7,3.1 7.2,6.9 0.2,4 -3,7.4 -7,7.6 z"
          />
          <path
            id="XMLID_8046_"
            d="m 771.3,725.3 c -4.1,0.1 -7.3,-3 -7.3,-7.2 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,4 -3.1,7.4 -7.1,7.5 z"
          />
          <path
            id="XMLID_8045_"
            d="m 795.2,710.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.2 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_192_"
            d="m 843.3,710.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.2 -6.9,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8032_"
            d="m 754.4,742.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_196_"
            d="m 729.9,742.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8031_"
            d="m 778.4,742 c 0,4 -3.2,7.3 -7.3,7.3 -3.9,0 -7,-2.9 -7.1,-6.9 -0.1,-4.1 2.9,-7.5 7,-7.6 3.9,-0.1 7.4,3.2 7.4,7.2 z"
          />
          <path
            id="XMLID_8013_"
            d="m 747.1,773.3 c -4.1,0 -7.2,-3.2 -7.1,-7.4 0.1,-3.9 3.1,-7 7,-7.1 4,-0.1 7.4,3.2 7.4,7.2 0,4 -3.2,7.3 -7.3,7.3 z"
          />
          <path
            id="XMLID_8012_"
            d="m 778.4,766.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_7992_"
            d="m 754.4,789.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_7344_"
            d="m 609.5,575.9 c 0.2,-0.2 0.4,-0.4 0.6,-0.6 0,0.1 0,0.3 0,0.4 -0.2,0.1 -0.5,0.1 -0.7,0.2 z"
          />
          <path
            id="XMLID_7337_"
            d="m 609.4,575.8 c 0,0.4 -0.1,0.7 -0.1,1.1 -0.1,-0.1 -0.2,-0.2 -0.2,-0.3 0.1,-0.2 0.3,-0.5 0.5,-0.7 -0.1,0 -0.2,-0.1 -0.2,-0.1 z"
          />
        </g>
        <g
          id="southA"
          fill={region === "south-america" ? "#244588" : undefined}
        >
          <path
            id="XMLID_8486_"
            d="m 399.8,626.9 c 4,0.3 6.8,3.7 6.5,7.9 -0.3,3.8 -3.6,6.7 -7.5,6.5 -4,-0.2 -7.2,-3.8 -6.9,-7.7 0.3,-4 3.8,-7 7.9,-6.7 z"
          />
          <path
            id="XMLID_8485_"
            d="m 423.2,641.3 c -4,0 -7.4,-3.3 -7.3,-7.3 0,-4 3.4,-7.2 7.4,-7.1 3.9,0.1 6.9,3.1 7,7 0.1,4.2 -3,7.4 -7.1,7.4 z"
          />
          <path
            id="XMLID_8476_"
            d="m 406.3,658.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_8475_"
            d="m 430.3,658.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8474_"
            d="m 454.3,658.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.2 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_8468_"
            d="m 399,674.9 c 4.1,-0.1 7.3,3 7.3,7.2 0,4 -2.9,7.1 -6.7,7.2 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_237_"
            d="m 375.3,674.9 c 4.1,-0.1 7.3,3 7.3,7.2 0,4 -2.9,7.1 -6.7,7.2 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8467_"
            d="m 430.3,682.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8466_"
            d="m 454.3,682.3 c -0.1,4.1 -3.3,7.2 -7.4,7.1 -3.8,-0.2 -6.9,-3.3 -7,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 4.1,-0.1 7.2,3.1 7.1,7.3 z"
          />
          <path
            id="XMLID_8465_"
            d="m 478.3,682.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-3.8 3.3,-6.9 7.2,-6.9 4.1,0 7.2,3.1 7.2,7.2 z"
          />
          <path
            id="XMLID_8464_"
            d="m 502.3,681.9 c 0.1,4.2 -3,7.4 -7,7.4 -4,0 -7.4,-3.2 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.1,-0.2 7.4,2.8 7.5,7 z"
          />
          <path
            id="XMLID_8460_"
            d="m 399.4,713.3 c -4,0.1 -7.4,-3.1 -7.5,-7.1 -0.1,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.1,4.2 -2.9,7.4 -6.9,7.5 z"
          />
          <path
            id="XMLID_8459_"
            d="m 422.9,698.9 c 4.1,-0.1 7.3,3 7.4,7.1 0,4 -2.8,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-3.9 3,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8458_"
            d="m 454.3,706.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_8457_"
            d="m 471.3,713.3 c -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-4 3.3,-7.3 7.3,-7.3 3.9,0 7,3 7.1,6.9 0.2,4.3 -2.8,7.5 -6.9,7.6 z"
          />
          <path
            id="XMLID_8456_"
            d="m 502.3,706.1 c 0,4.1 -3.2,7.3 -7.2,7.2 -4,-0.1 -7.3,-3.5 -7.2,-7.4 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,-0.1 7.3,3 7.3,7.2 z"
          />
          <path
            id="XMLID_8452_"
            d="m 430.3,730.2 c 0,4.1 -3.3,7.3 -7.4,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.3 4.2,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_8451_"
            d="m 447.2,722.9 c 4.1,0 7.2,3.2 7.1,7.4 -0.1,3.9 -3.1,7 -7,7 -4,0.1 -7.4,-3.2 -7.5,-7.2 0,-3.9 3.4,-7.2 7.4,-7.2 z"
          />
          <path
            id="XMLID_8450_"
            d="m 478.3,730.2 c 0,4.1 -3.3,7.3 -7.3,7.1 -3.8,-0.1 -7,-3.3 -7.1,-7.1 -0.1,-4 3.2,-7.3 7.2,-7.4 4.1,0.1 7.2,3.2 7.2,7.4 z"
          />
          <path
            id="XMLID_8449_"
            d="m 495,737.3 c -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-4 3.6,-7.1 7.6,-6.9 3.9,0.2 6.8,3.2 6.8,7.2 0,4.1 -3.2,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_244_"
            d="m 519,737.3 c -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.2,-4 3.6,-7.1 7.6,-6.9 3.9,0.2 6.8,3.2 6.8,7.2 0,4.1 -3.2,7.3 -7.3,7.2 z"
          />
          <path
            id="XMLID_8444_"
            d="m 430.3,754.1 c 0,4.1 -3.2,7.3 -7.3,7.2 -4,-0.1 -7.3,-3.5 -7.1,-7.5 0.1,-3.8 3.3,-6.9 7.1,-7 4.1,0 7.3,3.1 7.3,7.3 z"
          />
          <path
            id="XMLID_8443_"
            d="m 447.1,746.9 c 4.1,0 7.2,3.1 7.2,7.3 0,3.9 -3,7 -6.9,7.1 -4,0.1 -7.4,-3 -7.6,-7 0,-4 3.3,-7.4 7.3,-7.4 z"
          />
          <path
            id="XMLID_8442_"
            d="m 471.2,761.3 c -4,0 -7.3,-3.3 -7.3,-7.3 0.1,-4 3.4,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7 0,4.2 -3.1,7.4 -7.1,7.4 z"
          />
          <path
            id="XMLID_243_"
            d="m 495.2,761.3 c -4,0 -7.3,-3.3 -7.3,-7.3 0.1,-4 3.4,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7 0,4.2 -3.1,7.4 -7.1,7.4 z"
          />
          <path
            id="XMLID_8432_"
            d="m 430.3,777.8 c 0.1,4.2 -2.9,7.4 -7,7.5 -4,0 -7.4,-3.2 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.1,-0.2 7.4,2.8 7.5,6.9 z"
          />
          <path
            id="XMLID_8431_"
            d="m 454.3,778.3 c -0.1,4.1 -3.4,7.2 -7.4,7 -3.8,-0.2 -6.9,-3.4 -7,-7.1 0,-4 3.3,-7.3 7.3,-7.3 4.1,0 7.2,3.2 7.1,7.4 z"
          />
          <path
            id="XMLID_8430_"
            d="m 471,770.9 c 4.1,-0.1 7.3,3 7.3,7.1 0,4 -2.9,7.1 -6.7,7.3 -4,0.2 -7.5,-2.9 -7.7,-6.9 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8421_"
            d="m 430.3,802.3 c -0.1,4.1 -3.4,7.2 -7.4,7 -3.8,-0.2 -6.9,-3.4 -7,-7.1 0,-4 3.3,-7.3 7.3,-7.3 4.1,0 7.2,3.2 7.1,7.4 z"
          />
          <path
            id="XMLID_8420_"
            d="m 454.3,801.8 c 0.1,4.2 -2.9,7.4 -7,7.5 -4,0 -7.4,-3.2 -7.4,-7.2 0,-3.8 3.1,-7 6.9,-7.2 4.1,-0.2 7.4,2.8 7.5,6.9 z"
          />
          <path
            id="XMLID_8418_"
            d="m 399.1,857.3 c -4,0 -7.3,-3.4 -7.2,-7.4 0.1,-4 3.5,-7.2 7.5,-7.1 3.9,0.1 6.9,3.1 6.9,7.1 0,4.2 -3.1,7.4 -7.2,7.4 z"
          />
          <path
            id="XMLID_8417_"
            d="m 406.3,874 c 0.1,4.2 -3.1,7.4 -7.1,7.3 -4,0 -7.3,-3.4 -7.3,-7.3 0.1,-3.8 3.2,-7 7,-7.1 4.1,-0.1 7.3,2.9 7.4,7.1 z"
          />
          <path
            id="XMLID_8105_"
            d="m 411.2,638.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.1 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8104_"
            d="m 435.1,638.8 c 4,0 7.4,3.3 7.3,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.1,-4.1 3,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8090_"
            d="m 394.4,669.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7.1,-2.8 -7.3,-6.7 -0.2,-4.1 2.8,-7.5 6.8,-7.7 4.1,-0.3 7.6,3 7.7,7 z"
          />
          <path
            id="XMLID_8089_"
            d="m 411.2,662.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.2 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8088_"
            d="m 435.4,662.8 c 4,0.2 7.2,3.7 7,7.6 -0.2,4 -3.6,7.1 -7.7,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.3,-3.9 3.6,-6.9 7.4,-6.8 z"
          />
          <path
            id="XMLID_8087_"
            d="m 466.4,670.3 c -0.1,4 -3.5,7.1 -7.6,7 -4.1,-0.2 -7,-3.5 -6.8,-7.6 0.2,-3.8 3.5,-6.9 7.3,-6.8 3.9,0 7.2,3.5 7.1,7.4 z"
          />
          <path
            id="XMLID_8073_"
            d="m 394.4,693.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8072_"
            d="m 418.4,694.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4.1,-0.1 7.5,3.3 7.4,7.3 z"
          />
          <path
            id="XMLID_8071_"
            d="m 435.2,686.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.8,-3.2 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_8070_"
            d="m 466.4,694.1 c 0,4 -3.3,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-7 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_8069_"
            d="m 483.3,701.3 c -4.1,0.1 -7.3,-3 -7.3,-7.2 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,4 -3.1,7.4 -7.1,7.5 z"
          />
          <path
            id="XMLID_8068_"
            d="m 514.4,694.3 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.2 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.8 4,0 7.3,3.4 7.2,7.4 z"
          />
          <path
            id="XMLID_8067_"
            d="m 531.1,686.8 c 4,0 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.2,-4.1 2.9,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8057_"
            d="m 519,698.9 c 3.9,-0.1 7.4,3 7.2,6.7 -0.2,4.1 -2.3,7.6 -6.7,7.8 -4,0.1 -7.4,-3 -7.6,-7 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_245_"
            d="m 519,674.9 c 3.9,-0.1 7.4,3 7.2,6.7 -0.2,4.1 -2.3,7.6 -6.7,7.8 -4,0.1 -7.4,-3 -7.6,-7 -0.2,-4 3.1,-7.4 7.1,-7.5 z"
          />
          <path
            id="XMLID_8053_"
            d="m 418.4,717.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_8052_"
            d="m 442.4,718.3 c -0.1,4 -3.5,7.2 -7.5,7 -4.1,-0.2 -7.1,-3.4 -6.9,-7.6 0.2,-3.8 3.4,-6.9 7.2,-6.8 4,0 7.3,3.4 7.2,7.4 z"
          />
          <path
            id="XMLID_8051_"
            d="m 466.4,718.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4.1,-0.1 7.5,3.3 7.4,7.3 z"
          />
          <path
            id="XMLID_8050_"
            d="m 490.4,718.2 c -0.1,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8049_"
            d="m 507.2,725.3 c -4.1,0 -7.2,-3.1 -7.2,-7.3 0,-3.9 3,-7 6.9,-7.2 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,4 -3.2,7.3 -7.2,7.4 z"
          />
          <path
            id="XMLID_8036_"
            d="m 435.1,734.8 c 4,0 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.2,-4.1 2.9,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_238_"
            d="m 411.1,734.8 c 4,0 7.4,3.3 7.4,7.3 0,4 -3.3,7.2 -7.4,7.2 -3.9,-0.1 -6.9,-3 -7,-7 -0.2,-4.1 2.9,-7.4 7,-7.5 z"
          />
          <path
            id="XMLID_8035_"
            d="m 466.4,742 c 0,4 -3.3,7.3 -7.3,7.2 -3.9,0 -7,-3 -7.1,-6.9 -0.1,-4.1 3,-7.5 7,-7.6 3.9,0 7.4,3.4 7.4,7.3 z"
          />
          <path
            id="XMLID_8034_"
            d="m 490.4,742.2 c -0.1,4 -3.4,7.2 -7.5,7.1 -4.1,-0.1 -7.1,-3.4 -6.9,-7.5 0.2,-3.9 3.3,-6.9 7.1,-6.9 4,-0.1 7.4,3.3 7.3,7.3 z"
          />
          <path
            id="XMLID_8033_"
            d="m 514.4,741.9 c 0.1,4 -3.1,7.3 -7.2,7.4 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.8,-7.5 6.9,-7.7 3.9,-0.1 7.4,3.1 7.5,7.1 z"
          />
          <path
            id="XMLID_8015_"
            d="m 434.9,758.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_239_"
            d="m 410.9,758.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_8014_"
            d="m 459.3,773.3 c -4.1,0.1 -7.3,-3 -7.3,-7.1 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,3.9 -3,7.3 -7.1,7.4 z"
          />
          <path
            id="XMLID_242_"
            d="m 483.3,773.3 c -4.1,0.1 -7.3,-3 -7.3,-7.1 0,-3.9 2.9,-7 6.8,-7.3 3.9,-0.2 7.5,3 7.6,7 0.2,3.9 -3.1,7.3 -7.1,7.4 z"
          />
          <path
            id="XMLID_7995_"
            d="m 411.3,782.8 c 4,0.1 7.3,3.6 7.1,7.5 -0.2,4 -3.6,7.1 -7.6,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.2,-3.8 3.5,-6.8 7.2,-6.7 z"
          />
          <path
            id="XMLID_7994_"
            d="m 435.2,782.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.2 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_7993_"
            d="m 458.9,782.8 c 3.9,-0.2 7.4,3.1 7.5,7.1 0.1,3.8 -2.9,7 -6.7,7.3 -4.1,0.3 -7.5,-2.7 -7.7,-6.8 -0.2,-4 2.9,-7.4 6.9,-7.6 z"
          />
          <path
            id="XMLID_7986_"
            d="m 411.4,806.8 c 4,0.2 7.2,3.7 7,7.6 -0.2,4 -3.6,7.1 -7.7,6.9 -4.1,-0.2 -7,-3.5 -6.7,-7.7 0.3,-3.9 3.6,-6.9 7.4,-6.8 z"
          />
          <path
            id="XMLID_7985_"
            d="m 442.4,814.1 c 0,4 -3.4,7.2 -7.4,7.1 -4.1,-0.1 -7.1,-3.3 -7,-7.5 0.1,-3.9 3.3,-6.9 7.1,-7 4,0.1 7.3,3.5 7.3,7.4 z"
          />
          <path
            id="XMLID_7984_"
            d="m 430.3,825.9 c 0.1,3.8 -3.4,7.5 -7.1,7.4 -4,0 -7.3,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.2 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_240_"
            d="m 406.3,825.9 c 0.1,3.8 -3.4,7.5 -7.1,7.4 -4,0 -7.3,-3.3 -7.3,-7.3 0,-3.8 3.1,-7 7,-7.1 4.1,-0.2 7.3,2.9 7.4,7 z"
          />
          <path
            id="XMLID_7983_"
            d="m 418.4,837.9 c 0.1,4 -3.2,7.3 -7.2,7.3 -3.9,0 -7,-2.9 -7.2,-6.8 -0.2,-4.1 2.9,-7.5 6.9,-7.6 3.9,-0.1 7.4,3.2 7.5,7.1 z"
          />
          <path
            id="XMLID_7981_"
            d="m 411.2,854.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.1 -6.9,-7.1 0,-4 3.2,-7.3 7.2,-7.3 z"
          />
          <path
            id="XMLID_241_"
            d="m 411.2,878.8 c 4,0.1 7.3,3.5 7.2,7.4 -0.1,4 -3.5,7.2 -7.5,7 -3.9,-0.1 -6.9,-3.1 -6.9,-7.1 0,-4.1 3.2,-7.4 7.2,-7.3 z"
          />
          <path
            id="XMLID_7333_"
            d="m 365.5,640 c 0,-0.1 0.1,-0.2 0.1,-0.3 0.1,0.2 0.2,0.3 0.3,0.5 -0.1,-0.1 -0.3,-0.2 -0.4,-0.2 z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;

export const Map = ({ region }: Props) => {
  const classes = useStyles();
  return (
    <Paper variant="outlined" square className={classes.Paper3}>
      <Typography
        variant="h6"
        display="block"
        style={{ fontWeight: 600 }}
        gutterBottom
      >
        Map
      </Typography>

      <SvgComponent region={region} />
    </Paper>
  );
};
