import React, { Dispatch, SetStateAction } from "react";

import Paper from "@material-ui/core/Paper";
import { useQuery } from "react-query";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DownloaderRows } from "./DownloaderRows";
import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    Paper2: {
      maxWidth: "100%",
      padding: "18px",
      border: "1 , 5 px solid",
      borderRadius: "4px",
      height: "100%",
      marginTop: "20px",
      display: "flex",
      flexDirection: "column",
      marginBottom: 20,
    },
    Paper: {
      maxWidth: "100%",
      padding: "18px",
      border: "1 , 5 px solid",
      borderRadius: "4px",
      height: "100%",
      marginTop: 20,
      display: "flex",
      flexDirection: "column",
      marginBottom: 20,
    },
    PaperContent: {
      overflow: "auto",
      maxHeight: 800,
    },
  })
);

export interface EntitiesItem {
  import_type_name: string;
  option: string;
  export_time_stamp: string;
  usage: string;
  url: string;
  restoreInstructions: string;
  typeDescription: string;
}

export interface DatasetItem {
  name: string;
  region_name: string;
  parent: string;
  entities: EntitiesItem[];
}

interface Props {
  state: regionState;
}

interface regionState {
  region: string;
  setRegion: Dispatch<SetStateAction<string>>;
}

export interface Dataset {
  datasets: DatasetItem[];
}

const regionOrder = {
  africa: "Africa",
  "north-america": "North America",
  "central-america": "Central America",
  "south-america": "South America",
  antarctica: "Antarctica",
  "australia-oceania": "Australia / Oceania",
  europe: "Europe",
  russia: "Russia",
  asia: "Asia",
};

export const DownloaderContent = ({ state }: Props) => {
  const { isLoading, error, data } = useQuery("repoData", () =>
    fetch("https://gis.cybertec-postgresql.com/datasets.json").then(
      (res) => res.json() as Promise<Dataset>
    )
  );

  const classes = useStyles();

  if (isLoading) {
    return (
      <Paper variant="outlined" square className={classes.Paper}>
        <Typography
          variant="h6"
          display="block"
          style={{ fontWeight: 600 }}
          gutterBottom
        >
          Download
        </Typography>
        <CircularProgress />
      </Paper>
    );
  }

  if (error) console.log("An error has occurred: ");

  return (
    <Paper variant="outlined" square className={classes.Paper2}>
      <Typography
        variant="h6"
        display="block"
        style={{ fontWeight: 600 }}
        gutterBottom
      >
        Download
      </Typography>
      <div className={classes.PaperContent}>
        {(
          Object.keys(regionOrder) as unknown as (keyof typeof regionOrder)[]
        ).map((region) => {
          const name = regionOrder[region];
          const dataItem: DatasetItem | undefined = data?.datasets.find(
            (dataset) => dataset.name === region
          );
          if (dataItem !== undefined) {
            return (
              <DownloaderRows
                data={data}
                dataItem={dataItem}
                name={name}
                state={{ ...state }}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    </Paper>
  );
};
